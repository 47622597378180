import { createAsyncThunk } from '@reduxjs/toolkit';
import articles from '@services/articles';

export const getArticles = createAsyncThunk(
    'articles/getArticlesStatus',
    async ({ onlyPending }, { rejectWithValue }) => {
        
        const response = await articles.getArticles({ onlyPending });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getPublishedArticles = createAsyncThunk(
    'articles/getPublishedArticlesStatus',
    async (_, { rejectWithValue }) => {
        const response = await articles.getPublishedArticles();

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);


export const createArticle = createAsyncThunk(
    'articles/createArticleStatus',
    async ({ article, files, onComplete, onError }, { rejectWithValue }) => {
        const response = await articles.createArticle({ article, files });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const updateArticle = createAsyncThunk(
    'articles/updateArticleStatus',
    async ({ article, files, onComplete, onError }, { rejectWithValue }) => {
        const response = await articles.updateArticle({ article, files });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const deleteArticle = createAsyncThunk(
    'articles/deleteArticleStatus',
    async ({ id, onComplete, onError }, { rejectWithValue }) => {
        const response = await articles.deleteArticle({ id });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const updateArticleAutoRenew = createAsyncThunk(
    'articles/updateArticleAutoRenewStatus',
    async ({ userId, id, autoRenew, onError }, { rejectWithValue, dispatch }) => {
        const response = await articles.updateArticleAutoRenew({ id, autoRenew });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        dispatch(getUserArticles({ id: userId }));
        return response;
    }
);

export const getUserArticles = createAsyncThunk(
    'articles/getUserArticlesStatus',
    async ({ id, publishedOnly }, { rejectWithValue }) => {
        const response = await articles.getUserArticles({ id, publishedOnly });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getArticleById = createAsyncThunk(
    'articles/getArticleByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await articles.getArticleById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const reviewArticle = createAsyncThunk(
    'articles/reviewArticleStatus',
    async ({ id, approve, onComplete, onError }, { rejectWithValue }) => {
        const response = await articles.reviewArticle(id, approve);

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);
