import React, { useState, useEffect } from "react";
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { 
    getUserGroupById as getUserGroupByIdAction,
    updateUserGroup as updateUserGroupAction
} from '@redux/slices/usersSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import ConfirmationDialog from './ConfirmationDialog';
import styles from './styles.module.css';

const EditUserGroup = () => {
    useRequiredAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { currentUserGroup } = useSelector((state) => state.users);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [ userGroupFields, setUserGroupFields ] = useState({
        groupName: '',
        description: ''
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    useEffect(() => {
        const setUserGroupData = async () => {
            const user = {
                ...currentUserGroup
            };
            setUserGroupFields(user);
        };
        if (currentUserGroup.id) {
            setUserGroupData();
        } else {
            if (id) {
                dispatch(getUserGroupByIdAction({ id }));
            }
        }
    }, [currentUserGroup, id]);

    const handleUserGroupFieldsChange = e => {
        setUserGroupFields({
            ...userGroupFields,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        setConfirmDialogOpen(true);
    };

    const confirmUpdate = () => {
        if(!userGroupFields.id) return;
        dispatch(updateUserGroupAction({
            userGroupData: userGroupFields,
            onComplete: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.EditUserGroup.editUserGroupSuccessMsg'),
                    severity: 'success'
                }));
            },
            onError: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.EditUserGroup.editUserGroupFailedMsg'),
                    severity: 'error'
                }));
            }
        }));
    };

	return (
        <div className={styles.container}>
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                confirmUpdate={confirmUpdate}
            />
            <h1>{t('screens.EditUserGroup.pageHeader')}</h1>
            <TextField
                className={styles.itemTxtField}
                name='groupName'
                label={t('screens.EditUserGroup.groupNameLbl')}
                variant="outlined"
                value={userGroupFields.groupName}
                onChange={handleUserGroupFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='description'
                label={t('screens.EditUserGroup.descriptionLbl')}
                variant="outlined"
                value={userGroupFields.description}
                onChange={handleUserGroupFieldsChange}
            />
            <Button 
                variant='contained'
                onClick={handleSubmit}
            >
                {t('screens.EditUserGroup.editBtn')}
            </Button>
        </div>
    );
};

export default EditUserGroup;
