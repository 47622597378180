import { createAsyncThunk } from '@reduxjs/toolkit';
import alerts from '@services/alerts';
import articles from '@services/articles';

export const getAlerts = createAsyncThunk(
    'alerts/getAlertsStatus',
    async ({ userId }, { rejectWithValue }) => {
        const alertCollection = await alerts.getAlerts({ userId });

        if (alerts.error) {
            return rejectWithValue(alertCollection);
        }

        const matches = await articles.getArticlesByAlerts(alertCollection);

        if (matches.error) {
            return rejectWithValue(matches);
        }

        return { alerts: alertCollection, matches };
    }
);

export const createAlert = createAsyncThunk(
    'alerts/createAlertStatus',
    async ({ alert, onComplete, onError }, { rejectWithValue }) => {
        const response = await alerts.createAlert({ alert });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const editAlert = createAsyncThunk(
    'alerts/editAlertStatus',
    async ({ alert, onComplete, onError }, { rejectWithValue }) => {
        const response = await alerts.editAlert({ alert });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const deleteAlert = createAsyncThunk(
    'alerts/deleteAlertStatus',
    async ({ id, onComplete, onError }, { rejectWithValue }) => {
        const response = await alerts.deleteAlert({ id });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const getAlertById = createAsyncThunk(
    'alerts/getAlertByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await alerts.getAlertById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);
