import React from 'react';
import { useDispatch } from 'react-redux';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { useSelector } from 'react-redux';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Toast = () => {
    const dispatch = useDispatch();
    const toast = useSelector((state) => state.toast.toastData);

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        dispatch(setToastDataAction({ ...toast, open: false }));
    };

    return (
        <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <div>
                <Alert onClose={handleClose} severity={toast.severity}>
                    {toast.msg}
                </Alert>
            </div>
        </Snackbar>
    );
};

export default Toast;
