import React from 'react';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { transformBoolOptionToString } from '@utils/optionsUtils';
import styles from './styles.module.css';

const ArticleData = ({ article }) => {
    const { t } = useTranslation();

    return (
        <Card className={styles.articleContainer}>
            <div className={styles.infoContainer}>
                <a href={process.env.REACT_APP_CANDYPROP_URL + '/article-view?id=' + article.id} target="_blank" rel="noreferrer">
                    <Typography variant="h5">{article.title}</Typography>
                </a>
                <Typography variant="h6">{article.description}</Typography>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.operationTypeLbl')}</span>
                        {t(`common.OperationType.${article.operationType}`)}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.priceLbl')}</span>
                        {article.price}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.currencyLbl')}</span>
                        {article.currency?.toUpperCase()}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.financedLbl')}</span>
                        {article.financed != null ? t(`common.Boolean.${transformBoolOptionToString(article.financed)}`) : null}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.notMoneyLbl')}</span>
                        {article.notMoney != null ? t(`common.Boolean.${transformBoolOptionToString(article.notMoney)}`) : null}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.bedroomsLbl')}</span>
                        {article.bedrooms}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.bathroomsLbl')}</span>
                        {article.bathrooms}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.balconyLbl')}</span>
                        {article.balcony != null ? t(`common.Boolean.${transformBoolOptionToString(article.balcony)}`) : null}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.garageLbl')}</span>
                        {article.garage != null ? t(`common.Boolean.${transformBoolOptionToString(article.garage)}`) : null}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.swimmingPoolLbl')}</span>
                        {article.swimmingPool != null ? t(`common.Boolean.${transformBoolOptionToString(article.swimmingPool)}`) : null}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.hectaresLbl')}</span>
                        {article.hectares}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.freeField1Lbl')}</span>
                        {article.freeField1}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.freeField2Lbl')}</span>
                        {article.freeField2}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.freeField3Lbl')}</span>
                        {article.freeField3}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ArticleReview.freeField4Lbl')}</span>
                        {article.freeField4}
                    </Typography>
                </div>
            </div>
        </Card>
    );
};

export default ArticleData;
