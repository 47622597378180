import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUserById as getUserByIdAction, updateUser as updateUserAction } from '@redux/slices/usersSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import { getImgFromPath } from '@utils/images';
import { ImageType } from '@utils/constants/ImageType';
import ConfirmationDialog from './ConfirmationDialog';
import styles from './styles.module.css';


const EditUser = () => {
    useRequiredAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.users);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [ userFields, setUserFields ] = useState({
        groupId: '',
        email: '',
        userName: '',
        phone: '',
        userFrom: '',
        userAddress: '',
        registeredFrom: '',
        customLink: '',
        emailVerified: false,
        stripeCustomerId: '',
        autoAcceptConections: true,
        refered: '',
        referedUpdated: false,
        articleAnnouncements: 0,
        appraiserAnnouncements: 0,
        nalfAnnouncements: 0,
        cpPoints: 0,
        hearAboutUs: '',
        subscriptionEmail: '',
        shareProfile: false,
        preferredLanguage: 'en',
        profileImgPath: ''
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    useEffect(() => {
        const setUserData = async () => {
            const user = {
                ...currentUser,
                img: getImgFromPath(currentUser.profileImgPath, ImageType.PROFILE)
            };
            setUserFields(user);
        };
        if (currentUser.id) {
            setUserData();
        } else {
            if (id) {
                dispatch(getUserByIdAction({ id }));
            }
        }
    }, [currentUser, id]);

    const handleUserFieldsChange = e => {
        setUserFields({
            ...userFields,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        setConfirmDialogOpen(true);
    };

    const confirmUpdate = () => {
        if(!userFields.id) return;
        dispatch(updateUserAction({
            userData: userFields,
            onComplete: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.EditUsers.editUsersuccessMsg'),
                    severity: 'success'
                }));
                navigate('/');
            },
            onError: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.EditUsers.editUserFailedMsg'),
                    severity: 'error'
                }));
            }
        }));
    };

    return (
        <div className={styles.container}>
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                confirmUpdate={confirmUpdate}
            />
            <h1>{t('screens.EditUsers.pageHeader')}</h1>
            <TextField
                className={styles.itemTxtField}
                name='groupId'
                label={t('screens.EditUsers.groupIdLbl')}
                variant="outlined"
                value={userFields.groupId}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='email'
                label={t('screens.EditUsers.emailLbl')}
                variant="outlined"
                value={userFields.email}
                disabled={userFields.registeredFrom !== 'candyprop'}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='userName'
                label={t('screens.EditUsers.userNameLbl')}
                variant="outlined"
                value={userFields.userName}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='phone'
                label={t('screens.EditUsers.phoneLbl')}
                variant="outlined"
                value={userFields.phone}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='userAddress'
                label={t('screens.EditUsers.userAddressLbl')}
                variant="outlined"
                value={userFields.userAddress}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='registeredFrom'
                label={t('screens.EditUsers.registeredFromLbl')}
                variant="outlined"
                value={userFields.registeredFrom}
                onChange={handleUserFieldsChange}
                disabled
            />
            <TextField
                className={styles.itemTxtField}
                name='customLink'
                label={t('screens.EditUsers.customLinkLbl')}
                variant="outlined"
                value={userFields.customLink}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='refered'
                label={t('screens.EditUsers.referedLbl')}
                variant="outlined"
                value={userFields.refered}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='articleAnnouncements'
                label={t('screens.EditUsers.articleAnnouncementsLbl')}
                variant="outlined"
                type='number'
                value={userFields.articleAnnouncements}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='appraiserAnnouncements'
                label={t('screens.EditUsers.appraiserAnnouncementsLbl')}
                variant="outlined"
                type='number'
                value={userFields.appraiserAnnouncements}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='nalfAnnouncements'
                label={t('screens.EditUsers.nalfAnnouncementsLbl')}
                variant="outlined"
                type='number'
                value={userFields.nalfAnnouncements}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='cpPoints'
                label={t('screens.EditUsers.cpPointsLbl')}
                variant="outlined"
                type='number'
                value={userFields.cpPoints}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='hearAboutUs'
                label={t('screens.EditUsers.hearAboutUsLbl')}
                variant="outlined"
                value={userFields.hearAboutUs}
                onChange={handleUserFieldsChange}
            />
            <TextField
                className={styles.itemTxtField}
                name='subscriptionEmail'
                label={t('screens.EditUsers.subscriptionEmailLbl')}
                variant="outlined"
                value={userFields.subscriptionEmail}
                onChange={handleUserFieldsChange}
            />
            <Button 
                variant='contained'
                onClick={handleSubmit}
            >
                {t('screens.EditUsers.editBtn')}
            </Button>
        </div>
    );
};

export default EditUser;
