import React, { useState, createContext, useContext } from 'react';

const AuthContext = createContext({
    isAuthenticated: false,
    login: () => {}
});

export function useAuthContext() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(() => {
        let token = false;
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('AuthCorpCPToken');
        }
        return token !== null;
    });

    const login = () => setAuthenticated(true);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login }}>{children}</AuthContext.Provider>
    );
};
