import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { signOut as signOutAction, getUser as getUserAction } from '@redux/slices/accountSlice';
import { useAuthContext } from '@providers/AuthProvider';

const useRequiredAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuthContext();
    const { userData } = useSelector((state) => state.account);

    useEffect(() => {
        if (userData) return;
        if (isAuthenticated) {
            dispatch(getUserAction());
        } else {
            dispatch(signOutAction());
            navigate('/sign-in');
            return;
        }
    }, [dispatch, navigate, isAuthenticated, userData]);

    return null;
};

export default useRequiredAuth;
