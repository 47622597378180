import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    markerPosition: {
        lat: null,
        lng: null
    },
    areaPosition: {
        latFrom: null,
        latTo: null,
        lngFrom: null,
        lngTo: null
    },
    center: [-27.43, -59.04]
};

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMarkerPosition: (state, action) => {
            state.markerPosition = action.payload;
        },
        setAreaPosition: (state, action) => {
            state.areaPosition = action.payload;
        },
        setCenter: (state, action) => {
            state.center = action.payload;
        }
    }
});

export const { setMarkerPosition, setAreaPosition, setCenter } = mapSlice.actions;

export default mapSlice.reducer;
