import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toastData: {
        open: false,
        title: '',
        msg: '',
        severity: 'success'
    }
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToastData: (state, action) => {
            //Default open and title values.
            const { open = true, title = '' } = action.payload;
            state.toastData = { open, title, ...action.payload };
        }
    }
});

export const { setToastData } = toastSlice.actions;

export default toastSlice.reducer;
