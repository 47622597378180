import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import CPLogo from '@images/candyLogo.png';
import styles from './styles.module.css';

const LoggedDesktopNavbar = ({ logOut }) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} className={styles.container}>
            <div>
                <img
                    src={CPLogo}
                    alt="candyprop logo"
                    className={styles.candyLogo}
                />
            </div>
            <div className={styles.navbarList}>
                <Grid item xs={12}>
                    <Link to="/">
                        <Button className={styles.navbarItem}>
                            {t('layout.LoggedNavbar.home')}
                        </Button>
                    </Link>
                    <Link to="/my-profile">
                        <Button className={styles.navbarItem}>
                            {t('layout.LoggedNavbar.profile')}
                        </Button>
                    </Link>
                    <Link to="/sign-in">
                        <Button className={styles.navbarItem} onClick={logOut}>
                            {t('layout.LoggedNavbar.signOut')}
                        </Button>
                    </Link>
                </Grid>
            </div>
        </Grid>
    );
};

LoggedDesktopNavbar.propTypes = {
    logOut: PropTypes.func.isRequired
};

export default LoggedDesktopNavbar;
