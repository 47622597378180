import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import useRequiredAuth from '@hooks/useRequiredAuth';
import useArticles from '@hooks/useArticles';
import useServices from '@hooks/useServices';
import styles from './styles.module.css';
import useReports from '../../hooks/useReports';
import usePublishedArticles from '@hooks/usePublishedArticles';
import usePublishedServices from '@hooks/usePublishedServices';

const Home = () => {
    useRequiredAuth();
    useReports();
    useArticles();
    usePublishedArticles();
    useServices();
    usePublishedServices();
    const { t } = useTranslation();
    const { reportsCollection } = useSelector((state) => state.reports);
    const { articlesCollection } = useSelector((state) => state.articles);
    const { publishedArticlesCollection } = useSelector((state) => state.articles);
    const { appraisersCollection, notarialAndLawFirmsCollection } = useSelector((state) => state.services);
    const { publishedAppraisersCollection, publishedNotarialAndLawFirmsCollection } = useSelector((state) => state.services);

    return (
        <div className={styles.container}>
            <Typography component="h1" variant="h4">
                {t('screens.Home.reviewTitle')}
            </Typography>

            <div className={styles.reviewSection}>
                <Button variant="contained" href="/user-groups">
                    {t('screens.Home.userGroupsBtn')}
                </Button>
            </div>

            <div className={styles.reviewSection}>
                <Button variant="contained" href="/manage-prices">
                    {t('screens.Home.managePricesBtn')}
                </Button>
            </div>

            <div className={styles.reviewSection}>
                <Button variant="contained" href="/manage-users">
                    {t('screens.Home.manageUsersBtn')}
                </Button>
            </div>

            <div className={styles.twoColumns}>
                <div>
                    <Typography component="h1" variant="h6" className={styles.subtext}>
                        {t('screens.Home.articlesText')} {articlesCollection?.length}
                    </Typography>
                    <Button variant="contained" href="/articles-review">
                        {t('screens.Home.reviewPendingArticlesBtn')}
                    </Button>
                </div>
                <div>
                    <Typography component="h1" variant="h6" className={styles.subtext}>
                        {t('screens.Home.publishedArticlesText')} {publishedArticlesCollection?.length}
                    </Typography>
                    <Button variant="contained" href="/published-articles-review">
                        {t('screens.Home.reviewPublishedArticlesBtn')}
                    </Button>
                </div>
            </div>

            <div className={styles.twoColumns}>
                <div>
                    <Typography component="h1" variant="h6" className={styles.subtext}>
                        {t('screens.Home.servicesText')} {appraisersCollection?.length + notarialAndLawFirmsCollection?.length}
                    </Typography>
                    <Button variant="contained" href="/services-review">
                        {t('screens.Home.reviewPendingServicesBtn')}
                    </Button>
                </div>
                <div>
                    <Typography component="h1" variant="h6" className={styles.subtext}>
                        {t('screens.Home.publishedServicesText')}{' '}
                        {publishedAppraisersCollection?.length + publishedNotarialAndLawFirmsCollection?.length}
                    </Typography>
                    <Button variant="contained" href="/published-services-review">
                        {t('screens.Home.reviewPublishedServicesBtn')}
                    </Button>
                </div>
            </div>

            <div className={styles.reviewSection}>
                <Typography component="h1" variant="h6" className={styles.subtext}>
                    {t('screens.Home.reportsText')} {reportsCollection?.length}
                </Typography>
                <Button variant="contained" href="/reports-review">
                    {t('screens.Home.reviewReportsBtn')}
                </Button>
            </div>
        </div>
    );
};

export default Home;
