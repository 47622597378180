import { createSlice } from '@reduxjs/toolkit';
import {
    signUp,
    signIn,
    signOut,
    getUser,
    searchUsers,
    updateUserData
} from '@redux/asyncActions/accountActions';

const initialState = {
    loginLoading: false,
    error: null,
    userData: null,
    success: false,
    searchUsersCollection: []
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signUp.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(signUp.fulfilled, (state) => {
            state.loginLoading = false;
            state.error = null;
            state.success = true;
        });
        builder.addCase(signUp.rejected, (state, action) => {
            state.loginLoading = false;
            state.error = action.payload;
            state.success = false;
        });

        builder.addCase(signIn.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.loginLoading = false;
            state.error = null;
            state.success = true;
        });
        builder.addCase(signIn.rejected, (state, action) => {
            state.loginLoading = false;
            state.error = action.payload;
            state.success = false;
        });

        builder.addCase(signOut.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(signOut.fulfilled, (state) => {
            state = initialState;
        });

        builder.addCase(getUser.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.loginLoading = false;
            state.error = null;
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.loginLoading = false;
            state.error = action.payload;
        });

        builder.addCase(searchUsers.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(searchUsers.fulfilled, (state, action) => {
            state.searchUsersCollection = action.payload;
            state.loginLoading = false;
            state.error = null;
        });
        builder.addCase(searchUsers.rejected, (state, action) => {
            state.loginLoading = false;
            state.error = action.payload;
        });

        builder.addCase(updateUserData.pending, (state) => {
            state.loginLoading = true;
            state.success = false;
        });
        builder.addCase(updateUserData.fulfilled, (state, action) => {
            state.loginLoading = false;
            state.error = null;
            state.userData = action.payload;
            state.success = true;
        });
        builder.addCase(updateUserData.rejected, (state, action) => {
            state.loginLoading = false;
            state.error = action.payload;
            state.success = false;
        });
    }
});

export { signUp, signIn, signOut, getUser, searchUsers, updateUserData };

export default accountSlice.reducer;
