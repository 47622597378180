import React from 'react';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const ServiceData = ({ service }) => {
    const { t } = useTranslation();

    return (
        <Card className={styles.serviceContainer}>
            <div className={styles.infoContainer}>
                <a
                    href={process.env.REACT_APP_CANDYPROP_URL + '/service-view?id=' + service.id + '&type=' + service.type}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Typography variant="h5">
                        <span>{t(`screens.ServiceReview.serviceType${service.type}`)}</span>
                    </Typography>
                </a>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.serviceNameLbl')}</span>
                        {service.serviceName}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.serviceLastNameLbl')}</span>
                        {service.serviceLastName}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.phoneLbl')}</span>
                        {service.phone}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.addressLbl')}</span>
                        {service.serviceAddress}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.collegeLbl')}</span>
                        {service.college}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.enrollmentLbl')}</span>
                        {service.enrollment}
                    </Typography>
                </div>
                <div className={styles.dataRow}>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.institutionEmailLbl')}</span>
                        {service.institutionEmail}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.institutionPhoneLbl')}</span>
                        {service.institutionPhone}
                    </Typography>
                    <Typography variant="h6" className={styles.dataItem}>
                        <span>{t('screens.ServiceReview.institutionAddressLbl')}</span>
                        {service.institutionAddress}
                    </Typography>
                </div>
            </div>
        </Card>
    );
};

export default ServiceData;
