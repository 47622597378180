import { createSlice } from '@reduxjs/toolkit';
import { getAnnouncements, updateAnnouncement } from '@redux/asyncActions/announcementsActions';

const initialState = {
    itemCollection: [],
    loading: false,
    error: null
};

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAnnouncements.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAnnouncements.fulfilled, (state, action) => {
            state.itemCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAnnouncements.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateAnnouncement.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateAnnouncement.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateAnnouncement.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export { getAnnouncements, updateAnnouncement };

export default announcementsSlice.reducer;
