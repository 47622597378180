import request from './request';

const getAnnouncements = async () => {
    const announcementsUrl = `get-items`;
    const announcements = await request({ url: announcementsUrl, method: 'get' });
    return announcements;
};

const updateAnnouncement = async ({ item }) => {
    const url = 'update-item';
    return await request({ url, method: 'put', data: { item } });
};

const AnnouncementsService = {
    getAnnouncements,
    updateAnnouncement
};

export default AnnouncementsService;
