import common from './common';
import layout from './layout';
import screens from './screens';

const commonModule = {
    layout,
    screens,
    common
};

export default commonModule;
