import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationRejectDialog = ({ open, handleClose, confirmReject }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        confirmReject();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('screens.ReportsReview.confirmationRejectDialogTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('screens.ReportsReview.confirmationRejectDialogContent')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} autoFocus variant="contained" color="error">
                    {t('screens.ReportsReview.confirmationRejectDialogConfirm')}
                </Button>
                <Button onClick={handleClose} variant="contained" color="inherit">
                    {t('screens.ReportsReview.confirmationRejectDialogCancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationRejectDialog;
