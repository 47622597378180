import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useServices from '@hooks/useServices';
import { reviewService as reviewServiceAction } from '@redux/slices/servicesSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import ServiceData from './ServiceData';
import ConfirmationApproveDialog from './ConfirmationApproveDialog';
import SuccessApproveDialog from './SuccessApproveDialog';
import ConfirmationRejectDialog from './ConfirmationRejectDialog';
import SuccessRejectDialog from './SuccessRejectDialog';
import styles from './styles.module.css';


const ServicesReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useServices();
    const [confirmApproveDialogOpen, setConfirmApproveDialogOpen] = useState(false);
    const [successApproveDialogOpen, setSuccessApproveDialogOpen] = useState(false);
    const [confirmRejectDialogOpen, setConfirmRejectDialogOpen] = useState(false);
    const [successRejectDialogOpen, setSuccessRejectDialogOpen] = useState(false);
    const [servicesCollection, setServicesCollection] = useState([]);
    const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

    const { appraisersCollection, notarialAndLawFirmsCollection } = useSelector(state => state.services);
    
    useEffect(() => {
        //Shuffle services 1 and 1.
        const arrayServices1 = appraisersCollection.map(appraiser => ({...appraiser, type: 'appraiser'}));
        const arrayServices2 = notarialAndLawFirmsCollection.map(nalf => ({...nalf, type: 'nalf'}));
        const result = [];
        let i, l = Math.min(arrayServices1.length, arrayServices2.length);

        for (i = 0; i < l; i++) {
            result.push(arrayServices1[i], arrayServices2[i]);
        }
        result.push(...arrayServices1.slice(l), ...arrayServices2.slice(l));

        setServicesCollection(result);
    }, [appraisersCollection, notarialAndLawFirmsCollection]);


    const currentService = servicesCollection[currentServiceIndex];
    if(servicesCollection.length === 0) {
        return (
            <div className={styles.pageContainer}>

            </div>
        );
    }


    const confirmApprove = () => {
        dispatch(reviewServiceAction({
            id: currentService.id,
            approve: true,
            type: currentService.type,
            onComplete: () => {
                setSuccessApproveDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ServiceReview.approveServiceFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

    const confirmReject = () => {
        dispatch(reviewServiceAction({
            id: currentService.id,
            approve: false,
            type: currentService.type,
            onComplete: () => {
                setSuccessRejectDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ServiceReview.rejectServiceFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

    if(!currentService) return (
        <div className={styles.pageContainer}>
            <div className={styles.noServicesLeftTextWrapper}>
                <Typography variant='h5' className={styles.noServicesLeftText}>
                    {t('screens.ServiceReview.noServicesLeft')}
                </Typography>
                <Button variant='contained' onClick={() => navigate('/')}>
                    {t('screens.ServiceReview.backBtn')}
                </Button>
            </div>
        </div>
    );

    return (
        <div className={styles.pageContainer}>
            <SuccessApproveDialog open={successApproveDialogOpen} handleClose={() => window.location.reload(false)} />
            <SuccessRejectDialog open={successRejectDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationApproveDialog
                open={confirmApproveDialogOpen}
                handleClose={() => setConfirmApproveDialogOpen(false)}
                confirmApprove={confirmApprove}
            />
            <ConfirmationRejectDialog
                open={confirmRejectDialogOpen}
                handleClose={() => setConfirmRejectDialogOpen(false)}
                confirmReject={confirmReject}
            />
            <Typography variant='h6' className={styles.announcementsLeft}>
                {t('screens.ServiceReview.announcementsLeftLbl')}
                {servicesCollection.length}
            </Typography>
            <ServiceData service={currentService} />
            <div className={styles.actionsContainer}>
                <Button variant='contained' onClick={() => setConfirmApproveDialogOpen(true)}>
                    {t('screens.ServiceReview.approveBtn')}
                </Button>
                <Button variant='contained' color='error' onClick={() => setConfirmRejectDialogOpen(true)}>
                    {t('screens.ServiceReview.rejectBtn')}
                </Button>
                <Button variant='contained' color='inherit' onClick={() => setCurrentServiceIndex(currentServiceIndex + 1)}>
                    {t('screens.ServiceReview.skipBtn')}
                </Button>
            </div>
        </div>
    );
};

export default ServicesReview;
