import request from './request';

const getUserById = async (id) => {
    const url = `get-user-by-id/${id}`;
    return await request({ url, method: 'get' });
};

const updateUser = async ({ userData }) => {
    const url = 'super-update-user';
    return await request({ url, method: 'put', data: { user: userData } });    
};

const getUserGroups = async () => {
    const url = 'get-user-groups';
    return await request({ url, method: 'get' });
};

const getUserGroupById = async (id) => {
    const url = `get-user-group-by-id/${id}`;
    return await request({ url, method: 'get' });
};

const addUserGroup = async ({ userGroupData }) => {
    const url = 'add-user-group';
    return await request({ url, method: 'post', data: { userGroup: userGroupData } });    
};

const updateUserGroup = async ({ userGroupData }) => {
    const url = 'update-user-group';
    return await request({ url, method: 'put', data: { userGroup: userGroupData } });    
};

const getUsersByGroup = async ({ id }) => {
    const url = `get-users-by-group-id/${id}`;
    return await request({ url, method: 'get' });
};

const incrementAnnouncementsByUserGroup = async ({ announcementsData }) => {
    const url = 'increment-announcements-by-group-id';
    return await request({ url, method: 'put', data: { ...announcementsData } });    
};

const deleteUserGroup = async ({ id }) => {
    const url = `delete-user-group/${id}`;
    return await request({ url, method: 'delete' });    
};

const UsersService = {
    getUserById,
    updateUser,
    getUserGroups,
    getUserGroupById,
    addUserGroup,
    updateUserGroup,
    getUsersByGroup,
    incrementAnnouncementsByUserGroup,
    deleteUserGroup
};

export default UsersService;
