import { createSlice } from '@reduxjs/toolkit';
import {
    getServices,
    getPublishedServices,
    getAppraisers,
    getNotarialAndLawFirms,
    createAppraiser,
    createNALF,
    getAppraiserById,
    getNALFById,
    deleteAppraiser,
    deleteNALF,
    reviewService
} from '@redux/asyncActions/servicesActions';

const initialState = {
    notarialAndLawFirmsCollection: [],
    publishedNotarialAndLawFirmsCollection: [],
    appraisersCollection: [],
    publishedAppraisersCollection: [],
    requestedPublishedServices: false,
    error: null,
    loading: false,
    currentService: {
        id: 0,
        serviceStatus: 'pending',
        lat: 0,
        lng: 0,
        serviceName: '',
        serviceLastName: '',
        phone: '',
        email: '',
        serviceAddress: '',
        college: '',
        enrollment: '',
        institutionEmail: '',
        institutionPhone: '',
        institutionAddress: '',
        imgPath: 'default.jpg'
    },
    filterParams: {
        includeApraisers: true,
        includeLawFirms: true
    }
};

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setCurrentService: (state, action) => {
            state.currentService = action.payload;
        },
        resetCurrentService: (state) => {
            state.currentService = initialState.currentService;
        },
        setFilterParams: (state, action) => {
            state.filterParams = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getServices.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getServices.fulfilled, (state, action) => {
            state.notarialAndLawFirmsCollection = action.payload.notarialAndLawFirms;
            state.appraisersCollection = action.payload.appraisers;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        
        builder.addCase(getPublishedServices.pending, (state) => {
            state.loading = true;
            state.requestedPublishedServices = true;
            state.error = null;
        });
        builder.addCase(getPublishedServices.fulfilled, (state, action) => {
            state.publishedNotarialAndLawFirmsCollection = action.payload.notarialAndLawFirms;
            state.publishedAppraisersCollection = action.payload.appraisers;
            state.loading = false;
            state.requestedPublishedServices = true;
            state.error = null;
        });
        builder.addCase(getPublishedServices.rejected, (state, action) => {
            state.loading = false;
            state.requestedPublishedServices = true;
            state.error = action.payload;
        });

        builder.addCase(getAppraisers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAppraisers.fulfilled, (state, action) => {
            state.appraisersCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAppraisers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getNotarialAndLawFirms.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getNotarialAndLawFirms.fulfilled, (state, action) => {
            state.notarialAndLawFirmsCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getNotarialAndLawFirms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createAppraiser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createAppraiser.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createAppraiser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createNALF.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createNALF.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createNALF.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getAppraiserById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAppraiserById.fulfilled, (state, action) => {
            state.loading = false;
            state.currentService = action.payload;
            state.error = null;
        });
        builder.addCase(getAppraiserById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getNALFById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getNALFById.fulfilled, (state, action) => {
            state.loading = false;
            state.currentService = action.payload;
            state.error = null;
        });
        builder.addCase(getNALFById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteAppraiser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteAppraiser.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteAppraiser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteNALF.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteNALF.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteNALF.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(reviewService.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(reviewService.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(reviewService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export {
    getServices,
    getPublishedServices,
    getAppraisers,
    getNotarialAndLawFirms,
    createAppraiser,
    createNALF,
    getAppraiserById,
    getNALFById,
    deleteAppraiser,
    deleteNALF,
    reviewService
};

export const { setCurrentService, resetCurrentService, setFilterParams } = servicesSlice.actions;

export default servicesSlice.reducer;
