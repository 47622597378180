import { createAsyncThunk } from '@reduxjs/toolkit';
import users from '@services/users';

export const getUserById = createAsyncThunk(
    'users/getUserByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await users.getUserById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUserStatus',
    async ({ userData, onComplete, onError }, { rejectWithValue }) => {
        const response = await users.updateUser({ userData });

        if (response.error) {
            onError(response.error);
            return rejectWithValue(response);
        }
        onComplete(response.updated);
        return response.updated;
    }
);

export const getUserGroups = createAsyncThunk(
    'users/getUserGroupsStatus',
    async (_, { rejectWithValue }) => {
        const response = await users.getUserGroups();

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getUserGroupById = createAsyncThunk(
    'users/getUserGroupByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await users.getUserGroupById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const addUserGroup = createAsyncThunk(
    'users/addUserGroupStatus',
    async ({ userGroupData, onComplete, onError }, { rejectWithValue }) => {
        const response = await users.addUserGroup({ userGroupData });

        if (response.error) {
            onError(response.error);
            return rejectWithValue(response);
        }
        onComplete(response);
        return response;
    }
);

export const updateUserGroup = createAsyncThunk(
    'users/updateUserGroupStatus',
    async ({ userGroupData, onComplete, onError }, { rejectWithValue }) => {
        const response = await users.updateUserGroup({ userGroupData });

        if (response.error) {
            onError(response.error);
            return rejectWithValue(response);
        }
        onComplete(response.updated);
        return response.updated;
    }
);

export const getUsersByGroup = createAsyncThunk(
    'users/getUsersByGroupStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await users.getUsersByGroup({ id });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const incrementAnnouncementsByUserGroup = createAsyncThunk(
    'users/incrementAnnouncementsByUserGroupStatus',
    async ({ announcementsData, onComplete, onError }, { rejectWithValue }) => {
        const response = await users.incrementAnnouncementsByUserGroup({ announcementsData });

        if (response.error) {
            onError(response.error);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const deleteUserGroup = createAsyncThunk(
    'users/deleteUserGroupStatus',
    async ({ id, onComplete, onError }, { rejectWithValue }) => {
        const response = await users.deleteUserGroup({ id });

        if (response.error) {
            onError(response.error);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);
