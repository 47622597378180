import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { store } from '@redux/store';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { AuthProvider } from '@providers/AuthProvider';
import App from './App';

const theme = createTheme();
function Render() {
    return (
        <ReduxProvider store={store}>
            <AuthProvider>
                <I18nextProvider i18n={i18n}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </I18nextProvider>
            </AuthProvider>
        </ReduxProvider>
    );
}

export default Render;