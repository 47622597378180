import React, { useState, useEffect } from "react";
import { Button, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getUserGroupById as getUserGroupByIdAction,
    incrementAnnouncementsByUserGroup as incrementAnnouncementsByUserGroupAction
} from '@redux/slices/usersSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import ConfirmationDialog from "./ConfirmationDialog";
import styles from './styles.module.css';

const IncrementAnnouncementsByGroup = () => {
    useRequiredAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { currentUserGroup } = useSelector((state) => state.users);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [ fields, setFields ] = useState({
        articleAnnouncements: 0,
        appraiserAnnouncements: 0,
        nalfAnnouncements: 0,
        cpPoints: 0
    });

    useEffect(() => {
        if (!currentUserGroup.id && id) {
            dispatch(getUserGroupByIdAction({ id }));
        }
    }, [currentUserGroup, id]);

    const handleFieldsChange = e => {
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        setConfirmDialogOpen(true);
    };

    const confirmIncrement = () => {
        if(!id) return;

        const announcementsData = {
            userGroupId: id
        };

        if (fields.articleAnnouncements) {
            announcementsData.articleAnnouncements = fields.articleAnnouncements;
        }

        if (fields.appraiserAnnouncements) {
            announcementsData.appraiserAnnouncements = fields.appraiserAnnouncements;
        }

        if (fields.nalfAnnouncements) {
            announcementsData.nalfAnnouncements = fields.nalfAnnouncements;
        }

        if (fields.cpPoints) {
            announcementsData.cpPoints = fields.cpPoints;
        }

        dispatch(incrementAnnouncementsByUserGroupAction({
            announcementsData,
            onComplete: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.IncrementAnnouncementsByGroup.increaseAnnouncementsSuccessMsg'),
                    severity: 'success'
                }));
            },
            onError: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.IncrementAnnouncementsByGroup.increaseAnnouncementsFailedMsg'),
                    severity: 'error'
                }));
            }
        }));
    };

	return (
        <div className={styles.pageContainer}>
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                confirmUpdate={confirmIncrement}
            />
            <h1 className={styles.pageHeader}>{t('screens.IncrementAnnouncementsByGroup.pageHeader')}</h1>

            <div className={styles.groupInfo}>
                <h3>{currentUserGroup.id}</h3>
                <h2 className={styles.groupName}>{currentUserGroup.groupName}</h2>
                <h3 className={styles.groupDescription}>{currentUserGroup.description}</h3>
            </div>

            <div className={styles.fieldsContainer}>
                <TextField
                    className={styles.itemTxtField}
                    name='articleAnnouncements'
                    label={t('screens.IncrementAnnouncementsByGroup.articleAnnouncementsLbl')}
                    variant="outlined"
                    value={fields.articleAnnouncements}
                    type='number'
                    onChange={handleFieldsChange}
                />
                <TextField
                    className={styles.itemTxtField}
                    name='appraiserAnnouncements'
                    label={t('screens.IncrementAnnouncementsByGroup.appraiserAnnouncementsLbl')}
                    variant="outlined"
                    value={fields.appraiserAnnouncements}
                    type='number'
                    onChange={handleFieldsChange}
                />
                <TextField
                    className={styles.itemTxtField}
                    name='nalfAnnouncements'
                    label={t('screens.IncrementAnnouncementsByGroup.nalfAnnouncementsLbl')}
                    variant="outlined"
                    value={fields.nalfAnnouncements}
                    type='number'
                    onChange={handleFieldsChange}
                />
                <TextField
                    className={styles.itemTxtField}
                    name='cpPoints'
                    label={t('screens.IncrementAnnouncementsByGroup.cpPointsLbl')}
                    variant="outlined"
                    value={fields.cpPoints}
                    type='number'
                    onChange={handleFieldsChange}
                />
                <Button 
                    variant='contained'
                    onClick={handleSubmit}
                >
                    {t('screens.IncrementAnnouncementsByGroup.incrementBtn')}
                </Button>
            </div>
        </div>
    );
};

export default IncrementAnnouncementsByGroup;
