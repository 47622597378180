import ArticleType from './ArticleType/ArticleType.json';
import OperationType from './OperationType/OperationType';
import Currency from './Currency/Currency.json';
import Boolean from './Boolean/Boolean.json';
import Rooms from './Rooms/Rooms.json';
import Hectares from './Hectares/Hectares.json';

const moduleDefault = {
    ArticleType,
    OperationType,
    Currency,
    Boolean,
    Rooms,
    Hectares
};

export default moduleDefault;
