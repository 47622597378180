import React, { useState } from "react";
import { Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    deleteUserGroup as deleteUserGroupAction,
} from '@redux/slices/usersSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import DeletionDialog from "./DeletionDialog";
import styles from './styles.module.css';

const UserGroup = ({ userGroup, ...rest }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ deletionDialogOpen, setDeletionDialogOpen] = useState(false);

    const confirmDeletion = () => {
        dispatch(deleteUserGroupAction({
            id: userGroup.id,
            onComplete: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.UserGroups.groupDeletionSuccessMsg'),
                    severity: 'success'
                }));
                window.location.reload();
            },
            onError: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.UserGroups.groupDeletionFailedMsg'),
                    severity: 'error'
                }));
            }
        }));
    };

	return (
        <Card {...rest} className={styles.userGroupContainer} >
            <DeletionDialog
                open={deletionDialogOpen}
                handleClose={() => setDeletionDialogOpen(false)}
                confirmDeletion={confirmDeletion}
            />
            <Typography><h3 className={styles.userGroupTitle}>{userGroup.groupName}</h3></Typography>
            <Typography className={styles.userGroupDescription}>{t('screens.UserGroups.groupIdLbl')} {userGroup.id}</Typography>
            <Typography className={styles.userGroupDescription}>{userGroup.description}</Typography>
            <div className={styles.btnsContainer}>
                <Button
                    variant="contained"
                    href={`increment-announcements-by-group?id=${userGroup.id}`}
                >
                    {t('screens.UserGroups.incrementAnnouncementsBtn')}
                </Button>
                <Button 
                    variant="contained"
                    href={`view-users-by-group?id=${userGroup.id}`}
                >
                    {t('screens.UserGroups.viewUsersBtn')}
                </Button>
                <Button
                    variant="contained"
                    href={`edit-user-group?id=${userGroup.id}`}
                >
                    {t('screens.UserGroups.editBtn')}
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    
                    onClick={() => setDeletionDialogOpen(true)}
                >
                    {t('screens.UserGroups.deleteBtn')}
                </Button>
            </div>
        </Card>
    );
};

export default UserGroup;
