import { createSlice } from '@reduxjs/toolkit';
import {
    getArticles,
    getPublishedArticles,
    getUserArticles,
    getArticleById,
    createArticle,
    updateArticle,
    deleteArticle,
    updateArticleAutoRenew,
    reviewArticle
} from '@redux/asyncActions/articlesActions';

const initialState = {
    articlesCollection: [],
    publishedArticlesCollection: [],
    requestedPublishedArticles: false,
    userArticlesCollection: [],
    error: null,
    loading: false,
    success: false,
    currentArticle: {
        id: 0,
        userId: 0,
        title: '',
        operationType: '',
        articleStatus: 'Pending',
        price: 0,
        lat: 0,
        lng: 0,
        articleType: '',
        currency: '',
        financed: false,
        notMoney: false,
        bedrooms: 0,
        bathrooms: 0,
        balcony: false,
        garage: false,
        swimmingPool: false,
        hectares: 0,
        articleAddress: '',
        description: '',
        appraised: false,
        freeField1: '',
        freeField2: '',
        freeField3: '',
        freeField4: '',
        articleDescription: '',
        imgs: []
    }
};

export const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        setCurrentArticle: (state, action) => {
            state.currentArticle = action.payload;
        },
        resetCurrentArticle: (state) => {
            state.currentArticle = null; //initialState.currentArticle;
        },
        resetSuccess: (state) => {
            state.success = null; //initialState.success;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getArticles.pending, (state) => {
            state.loading = true;
            state.requestedPublishedArticles = true;
            state.error = null;
        });
        builder.addCase(getArticles.fulfilled, (state, action) => {
            state.articlesCollection = action.payload;
            state.loading = false;
            state.requestedPublishedArticles = true;
            state.error = null;
        });
        builder.addCase(getArticles.rejected, (state, action) => {
            state.loading = false;
            state.requestedPublishedArticles = true;
            state.error = action.payload;
        });
        
        builder.addCase(getPublishedArticles.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getPublishedArticles.fulfilled, (state, action) => {
            state.publishedArticlesCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getPublishedArticles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getUserArticles.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserArticles.fulfilled, (state, action) => {
            state.userArticlesCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUserArticles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getArticleById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getArticleById.fulfilled, (state, action) => {
            state.currentArticle = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getArticleById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createArticle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createArticle.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateArticle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateArticle.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteArticle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteArticle.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateArticleAutoRenew.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateArticleAutoRenew.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateArticleAutoRenew.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(reviewArticle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(reviewArticle.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(reviewArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export {
    getArticles,
    getPublishedArticles,
    getUserArticles,
    getArticleById,
    createArticle,
    updateArticle,
    deleteArticle,
    updateArticleAutoRenew,
    reviewArticle
};
export const { setCurrentArticle, resetCurrentArticle } = articlesSlice.actions;

export default articlesSlice.reducer;
