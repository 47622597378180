import { ErrorReason } from './constants/ErrorReason';

const genericError = {
    error: true,
    reason: ErrorReason.UNKNOWN_ERROR,
    msg: 'Something went wrong.'
};

export const handleError = (error) => {
    if (error.response) {
        if (error.response.status < 500) {
            return {
                ...genericError,
                reason: ErrorReason.REQUEST_ERROR,
                msg: error.response.data?.error
            };
        }

        if (error.response.status >= 500) {
            return {
                ...genericError,
                reason: ErrorReason.INTERNAL_SERVER_ERROR,
                msg: error.response.data?.error
            };
        }
    }

    return genericError;
};
