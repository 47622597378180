import axios from 'axios';
import { URL } from '@utils/constants/URL';
import { handleError } from '@/utils/handleError';

const API_URL = URL.SERVER + '/rest/';

const client = axios.create({
    baseURL: API_URL
});

export const request = async ({ url, method, data }) => {
    const authToken = localStorage.getItem('AuthCorpCPToken');
    client.defaults.headers['Authorization'] = authToken;
    client.defaults.headers['Content-Type'] = 'application/json';
    try {
        const response = await client[method](url, data);

        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

export default request;
