import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeletionDialog = ({ open, handleClose, confirmDeletion }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        confirmDeletion();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('screens.UserGroups.deletionDialogTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('screens.UserGroups.deletionDialogContent')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} autoFocus variant="contained" color='error'>
                    {t('screens.UserGroups.deletionDialogConfirm')}
                </Button>
                <Button onClick={handleClose} variant="contained" color="inherit">
                    {t('screens.UserGroups.deletionDialogCancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletionDialog;
