import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Popover, MenuItem } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@mui/icons-material/Close';
import CPLogo from '@images/candyLogo.png';
import clsx from 'clsx';

const LoggedMobileNavbar = ({ open, setOpen, anchor, setAnchor, logOut }) => {
    const { t } = useTranslation();

    const handleMenu = (event) => {
        setOpen(!open);
        setAnchor(event.currentTarget);
    };

    const navbarClasses = open ? clsx(styles.container, styles.openedNav) : clsx(styles.container);

    return (
        <div className={navbarClasses}>
            <div>
                <img
                    src={CPLogo}
                    alt="candyprop logo"
                    className={styles.candyLogo}
                />
            </div>
            {open ? (
                <IconButton
                    edge="start"
                    aria-label="menu"
                    onClick={handleMenu}
                    className={styles.hamburgerBtn}
                >
                    <CloseIcon />
                </IconButton>
            ) : (
                <IconButton
                    edge="start"
                    aria-label="menu"
                    onClick={handleMenu}
                    className={styles.hamburgerBtn}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Popover
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                marginThreshold={-100}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                PaperProps={{
                    className: styles.menuContainer
                }}
                className={styles.outerMenuBg}
            >
                <MenuItem onClick={() => setOpen(false)}>
                    <Link to="/">
                        <h2 className={styles.menuItem}>{t('layout.LoggedNavbar.home')}</h2>
                    </Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <Link to="/my-profile">
                        <h2 className={styles.menuItem}>{t('layout.LoggedNavbar.profile')}</h2>
                    </Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <Link to="/sign-in">
                        <h2 className={styles.menuItem} onClick={logOut}>
                            {t('layout.LoggedNavbar.signOut')}
                        </h2>
                    </Link>
                </MenuItem>
            </Popover>
        </div>
    );
};

LoggedMobileNavbar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    anchor: PropTypes.any,
    setAnchor: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired
};

export default LoggedMobileNavbar;
