import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUserGroups as getUserGroupsAction } from '@redux/slices/usersSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import UserGroup from "./UserGroup";
import styles from './styles.module.css';


const UserGroups = () => {
    useRequiredAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    

    const { userGroupCollection } = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(getUserGroupsAction());
    }, []);


	return (
        <div className={styles.pageContainer}>
            <h1>{t('screens.UserGroups.pageHeader')}</h1>
            <Button
                className={styles.createGroupBtn}
                variant="contained"
                href="create-user-group"
            >
                {t('screens.UserGroups.createGroupBtn')}
            </Button>
            {userGroupCollection.map(userGroup => (
                <UserGroup key={userGroup.id} userGroup={userGroup} />
            ))}
        </div>
    );
};

export default UserGroups;
