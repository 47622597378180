import request from './request';
import formDataRequest from './formDataRequest';

const getArticles = async ({ onlyPending }) => {
    const url = `get-articles?onlyPending=${onlyPending}`;
    return await request({ url, method: 'get' });
};

const getPublishedArticles = async () => {
    const url = `get-articles?publishedOnly=true`;
    return await request({ url, method: 'get' });
};

const getUserArticles = async ({ id, publishedOnly }) => {
    const url = `get-articles?userId=${id}&publishedOnly=${publishedOnly}`;
    return await request({ url, method: 'get' });
};

const getArticleById = async (id) => {
    const url = `get-article/${id}`;
    return await request({ url, method: 'get' });
};

const getArticlesByAlerts = async (alerts) => {
    const url = `get-articles-by-alerts`;
    return await request({ url, method: 'post', data: { alerts } });
};

const createArticle = async ({ article, files }) => {
    const url = 'add-article';
    return await formDataRequest({ url, method: 'post', data: { article }, files });
};

const updateArticle = async ({ article, files }) => {
    const url = 'update-article';
    return await formDataRequest({ url, method: 'put', data: { article }, files });
};

const deleteArticle = async ({ id }) => {
    const url = `delete-article/${id}`;
    return await formDataRequest({ url, method: 'delete' });
};

const updateArticleAutoRenew = async ({ id, autoRenew }) => {
    const url = `manage-autorenew-article?id=${id}`;
    return await request({ url, method: 'put', data: { autoRenew } });
};

const reviewArticle = async (id, approve) => {
    const url = `approve-article?id=${id}&approve=${approve}`;
    return await request({ url, method: 'put' });
};

const ArticlesService = {
    getArticles,
    getPublishedArticles,
    getArticleById,
    getUserArticles,
    getArticlesByAlerts,
    createArticle,
    updateArticle,
    deleteArticle,
    updateArticleAutoRenew,
    reviewArticle
};

export default ArticlesService;
