import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getReports as getReportsAction } from '@redux/slices/reportsSlice';

const useReports = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);

    useEffect(() => {
        if(userData) {
            dispatch(getReportsAction({ onlyPending: true }));
        }
    }, [userData, dispatch]);

    return null;
};

export default useReports;
