import Home from './Home/Home.json';
import SignIn from './SignIn/SignIn.json';
import ArticleReview from './ArticleReview/ArticleReview.json';
import ServiceReview from './ServiceReview/ServiceReview.json';
import ManagePrices from './ManagePrices/ManagePrices.json';
import ManageUsers from './ManageUsers/ManageUsers.json';
import EditUsers from './EditUsers/EditUsers.json';
import Profile from './Profile/Profile.json';
import UserGroups from './UserGroups/UserGroups.json';
import CreateUserGroup from './CreateUserGroup/CreateUserGroup.json';
import EditUserGroup from './EditUserGroup/EditUserGroup.json';
import ViewUsersByGroup from './ViewUsersByGroup/ViewUsersByGroup.json';
import IncrementAnnouncementsByGroup from './IncrementAnnouncementsByGroup/IncrementAnnouncementsByGroup.json';
import ReportsReview from './ReportsReview/ReportsReview.json';

const moduleDefault = {
    Home,
    SignIn,
    ArticleReview,
    ServiceReview,
    ManagePrices,
    ManageUsers,
    EditUsers,
    Profile,
    UserGroups,
    CreateUserGroup,
    EditUserGroup,
    ViewUsersByGroup,
    IncrementAnnouncementsByGroup,
    ReportsReview
};

export default moduleDefault;
