import { createSlice } from '@reduxjs/toolkit';
import {
    getReports,
    reviewReport
} from '@redux/asyncActions/reportsActions';

const initialState = {
    reportsCollection: [],
    error: null,
    loading: false,
    success: false,
    currentReport: {
        id: 0,
        resourceId: '',
        reporterId: '',
        ownerId: '',
        title: '',
        description: '',
        type: '',
        status: '',
        creationDate: null,
        lastModificationDate: null
    }
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getReports.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getReports.fulfilled, (state, action) => {
            state.reportsCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getReports.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(reviewReport.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(reviewReport.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(reviewReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export {
    getReports,
    reviewReport
};

export default reportsSlice.reducer;
