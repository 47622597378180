import React, { useState, useEffect } from 'react';
import { 
    Card,
    Typography,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getArticleById as getArticleByIdAction } from '@redux/slices/articlesSlice';
import { urlToFile, getImgFromPath } from '@utils/images';
import { ImageType } from '@utils/constants/ImageType';
import { transformBoolOptionToString } from '@utils/optionsUtils';
import styles from './styles.module.css';

const ArticleData = ({ id }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [imgFiles, setImgsFiles] = useState([]);

    const { currentArticle: article} = useSelector(state => state.articles);

    useEffect(() => {
        if (id && !article.id) {
            dispatch(getArticleByIdAction({ id }));
        }
    }, [id, article, dispatch]);

    useEffect(() => {
        const loadImgs = async () => {
            const imgs = await Promise.all(
                article?.imgs.map(async (img) => {
                    return await urlToFile(getImgFromPath(img.path, ImageType.ARTICLE), img.id);
                })
            );
            setImgsFiles(imgs);
        }
        loadImgs();
    }, [article.imgs]);

    return (
        <Card className={styles.articleContainer}>
            <div className={styles.articleImgContainer}>
                <ImageList className={styles.imgList}>
                    {imgFiles.map((img, idx) => (
                        <ImageListItem key={img.name + img.lastModified + idx}>
                            <img src={URL.createObjectURL(img)} alt='' loading="lazy" />
                            <ImageListItemBar
                                title={img.name}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
            <div className={styles.infoContainer}>
                <Typography variant='h6'>
                    <span className={styles.articleLbl}>{t('screens.ReportsReview.articleTitleLbl')}</span> 
                    {article.title}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.articleLbl}>{t('screens.ReportsReview.articleDescriptionLbl')}</span> 
                    {article.description}
                </Typography>
                {article.operationType != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.operationTypeLbl')}</span> 
                        {t(`common.OperationType.${article.operationType}`)}
                    </Typography>
                )}
                <Typography variant='h6'>
                    <span className={styles.articleLbl}>{t('screens.ReportsReview.priceLbl')}</span> 
                    {article.price}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.articleLbl}>{t('screens.ReportsReview.currencyLbl')}</span> 
                    {article.currency?.toUpperCase()}
                </Typography>
                {article.financed != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.financedLbl')}</span> 
                        {t(`common.Boolean.${transformBoolOptionToString(article.financed)}`)}
                    </Typography>
                )}
                {article.notMoney != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.notMoneyLbl')}</span> 
                        {t(`common.Boolean.${transformBoolOptionToString(article.notMoney)}`)}
                    </Typography>
                )}
                {article.bedrooms != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.bedroomsLbl')}</span> 
                        {article.bedrooms}
                    </Typography>
                )}
                {article.bathrooms != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.bathroomsLbl')}</span> 
                        {article.bathrooms}
                    </Typography>
                )}
                {article.balcony != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.balconyLbl')}</span> 
                        {t(`common.Boolean.${transformBoolOptionToString(article.balcony)}`)}
                    </Typography>
                )}
                {article.garage != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.garageLbl')}</span> 
                        {t(`common.Boolean.${transformBoolOptionToString(article.garage)}`)}
                    </Typography>
                )}
                {article.swimmingPool != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.swimmingPoolLbl')}</span> 
                        {t(`common.Boolean.${transformBoolOptionToString(article.swimmingPool)}`)}
                    </Typography>
                )}
                {article.hectares != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.hectaresLbl')}</span> 
                        {article.hectares}
                    </Typography>
                )}
                {article.freeField1 != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.freeField1Lbl')}</span> 
                        {article.freeField1}
                    </Typography>
                )}
                {article.freeField2 != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.freeField2Lbl')}</span> 
                        {article.freeField2}
                    </Typography>
                )}
                {article.freeField3 != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.freeField3Lbl')}</span> 
                        {article.freeField3}
                    </Typography>
                )}
                {article.freeField4 != null && (
                    <Typography variant='h6'>
                        <span className={styles.articleLbl}>{t('screens.ReportsReview.freeField4Lbl')}</span> 
                        {article.freeField4}
                    </Typography>
                )}
            </div>
        </Card>
    );
};

export default ArticleData;
