import { createAsyncThunk } from '@reduxjs/toolkit';
import auth from '@services/auth';

export const signUp = createAsyncThunk(
    'account/signUpStatus',
    async ({ user, password, onComplete, onError }, { rejectWithValue }) => {
        const response = await auth.signUp({ user, password });
        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete();
        return response;
    }
);

export const signIn = createAsyncThunk(
    'account/signInStatus',
    async ({ email, password, strategy, token, onComplete, onError }, { rejectWithValue }) => {
        const response = await auth.signIn({ email, password, strategy, token });
        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete();
        return response.user;
    }
);

export const signOut = createAsyncThunk('account/signOutStatus', async (_, { rejectWithValue }) => {
    const response = await auth.signOut();

    if (response.error) {
        return rejectWithValue(response);
    }

    return response;
});

export const getUser = createAsyncThunk('account/getUserStatus', async (_, { rejectWithValue }) => {
    const response = await auth.getUser();

    if (response.error) {
        return rejectWithValue(response);
    }

    return response;
});

export const searchUsers = createAsyncThunk('account/searchUsersStatus', async ({ id, email, userName }, { rejectWithValue }) => {
    
    const response = await auth.searchUsers({ id, email, userName });
    if (response.error) {
        return rejectWithValue(response);
    }

    return response;
});

export const updateUserData = createAsyncThunk(
    'account/updateUserDataStatus',
    async ({ userData, files }, { rejectWithValue }) => {
        const response = await auth.updateUserData({ userData, files });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response.updated;
    }
);
