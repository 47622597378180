import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getArticles as getArticlesAction } from '@redux/slices/articlesSlice';

const useArticles = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);

    useEffect(() => {
        if(userData) {
            dispatch(getArticlesAction({ onlyPending: true }));
        }
    }, [userData, dispatch]);

    return null;
};

export default useArticles;
