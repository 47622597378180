import { createSlice } from '@reduxjs/toolkit';
import {
    getUserById,
    updateUser,
    getUserGroups,
    getUserGroupById,
    addUserGroup,
    updateUserGroup,
    getUsersByGroup,
    incrementAnnouncementsByUserGroup,
    deleteUserGroup
} from '@/redux/asyncActions/usersActions';

const initialState = {
    currentUser: {
        name: '',
        phoneNumber: '',
        email: '',
        address: ''
    },
    currentUserGroup: {
        groupName: '',
        description: ''
    },
    userGroupCollection: [],
    usersByGroupCollection: []
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getUserById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserById.fulfilled, (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUserById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getUserGroups.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserGroups.fulfilled, (state, action) => {
            state.userGroupCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUserGroups.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getUserGroupById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserGroupById.fulfilled, (state, action) => {
            state.currentUserGroup = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUserGroupById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(addUserGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(addUserGroup.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(addUserGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateUserGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateUserGroup.fulfilled, (state, action) => {
            state.currentUserGroup = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateUserGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getUsersByGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUsersByGroup.fulfilled, (state, action) => {
            state.usersByGroupCollection = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getUsersByGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(incrementAnnouncementsByUserGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(incrementAnnouncementsByUserGroup.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(incrementAnnouncementsByUserGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteUserGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteUserGroup.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteUserGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export {
    getUserById,
    updateUser,
    getUserGroups,
    getUserGroupById,
    addUserGroup,
    updateUserGroup,
    getUsersByGroup,
    incrementAnnouncementsByUserGroup,
    deleteUserGroup
};

export default usersSlice.reducer;
