import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Button, TextField, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import usePublishedServices from '@hooks/usePublishedServices';
import { reviewService as reviewServiceAction } from '@redux/slices/servicesSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import ServiceData from './ServiceData';
import ConfirmationRejectDialog from './ConfirmationRejectDialog';
import SuccessRejectDialog from './SuccessRejectDialog';
import styles from './styles.module.css';
import VirtualScroll from 'react-dynamic-virtual-scroll';

const PublishedServicesReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePublishedServices();
    const [search, setSearch] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [publishedServices, setPublishedServices] = useState([]);
    const [confirmRejectDialogOpen, setConfirmRejectDialogOpen] = useState(false);
    const [successRejectDialogOpen, setSuccessRejectDialogOpen] = useState(false);
    const [publishedServicesCollection, setPublishedServicesCollection] = useState([]);
    const { publishedAppraisersCollection, publishedNotarialAndLawFirmsCollection } = useSelector((state) => state.services);

    useEffect(() => {
        //Shuffle services 1 and 1.
        const arrayServices1 = publishedAppraisersCollection.map((appraiser) => ({ ...appraiser, type: 'appraiser' }));
        const arrayServices2 = publishedNotarialAndLawFirmsCollection.map((nalf) => ({ ...nalf, type: 'nalf' }));
        const result = [];
        let i,
            l = Math.min(arrayServices1.length, arrayServices2.length);

        for (i = 0; i < l; i++) {
            result.push(arrayServices1[i], arrayServices2[i]);
        }
        result.push(...arrayServices1.slice(l), ...arrayServices2.slice(l));

        setPublishedServicesCollection(result);
    }, [publishedAppraisersCollection, publishedNotarialAndLawFirmsCollection]);

    useEffect(() => {
        setPublishedServices(publishedServicesCollection);
    }, [publishedServicesCollection]);

    const searchServices = useCallback(() => {
        const filteredServices = publishedServicesCollection.filter((service) => {
            return service.serviceName.toLowerCase().includes(search.toLowerCase());
        });
        setPublishedServices(filteredServices);
    }, [publishedServicesCollection, search]);

    const confirmReject = () => {
        dispatch(
            reviewServiceAction({
                id: serviceId,
                approve: false,
                type: serviceType,
                onComplete: () => {
                    setSuccessRejectDialogOpen(true);
                },
                onError: () => {
                    dispatch(
                        setToastDataAction({
                            msg: t('screens.ServiceReview.rejectServiceFailedMsg'),
                            severity: 'error',
                        })
                    );
                },
            })
        );
    };

    const renderItem = useCallback(
        (rowIndex) => (
            <div key={publishedServices[rowIndex].id} className={styles['List-item']}>
                <ServiceData service={publishedServices[rowIndex]} />
                <div className={styles.actionsContainer}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setServiceId(publishedServices[rowIndex].id);
                            setServiceType(publishedServices[rowIndex].type);
                            setConfirmRejectDialogOpen(true);
                        }}
                    >
                        {t('screens.ServiceReview.rejectBtn')}
                    </Button>
                </div>
                <Divider />
            </div>
        ),
        [publishedServices, t]
    );

    return (
        <div className={styles.pageContainer}>
            <SuccessRejectDialog open={successRejectDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationRejectDialog
                open={confirmRejectDialogOpen}
                handleClose={() => setConfirmRejectDialogOpen(false)}
                confirmReject={confirmReject}
            />

            <div className={styles.flex}>
                <Typography variant="h6">
                    {t('screens.ServiceReview.appraisersPublished')}
                    {publishedAppraisersCollection.length}
                </Typography>
                <Typography variant="h6">
                    {t('screens.ServiceReview.nalfPublished')}
                    {publishedNotarialAndLawFirmsCollection.length}
                </Typography>
            </div>

            <div className={styles.searchContainer}>
                <TextField
                    label={t('screens.ServiceReview.search')}
                    variant="standard"
                    className={styles.searchInput}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Button variant="contained" className={styles.searchButton} onClick={searchServices}>
                    {t('screens.ServiceReview.search')}
                </Button>
            </div>

            <VirtualScroll
                className={styles.List}
                minItemHeight={440}
                totalLength={publishedServices.length}
                length={2}
                buffer={1}
                renderItem={renderItem}
            />
        </div>
    );
};

export default PublishedServicesReview;
