import React from 'react';
import { useSelector } from 'react-redux';
import useAnnouncements from '@hooks/useAnnouncements';
import AnnouncementCard from './AnnouncementCard';
import styles from './styles.module.css';


const ManagePrices = () => {
    useAnnouncements();
    const { itemCollection } = useSelector(state => state.announcements);

    return (
        <div className={styles.pageContainer}>
            {itemCollection.map(item => <AnnouncementCard item={item} key={item.id} />)}
        </div>
    );
};

export default ManagePrices;
