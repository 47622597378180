import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import es from './locales/es';

i18n.use(LanguageDetector).init({
    resources: {
        en: {
            translations: en
        },
        es: {
            translations: es
        }
    },

    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ','
    },

    react: {
        useSuspense: true
    }
});

export default i18n;
