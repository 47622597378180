import React, { useState } from 'react';
import {
    TextField,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { searchUsers as searchUsersAction } from '@redux/slices/accountSlice';
import styles from './styles.module.css';

const Search = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ searchFields, setSearchFields ] = useState({
        id: '',
        email: '',
        userName: ''
    });

    const handleSearchFieldsChange = e => {
        setSearchFields({
            ...searchFields,
            [e.target.name]: e.target.value
        });
    };

    const onSearch = () => {
        if(!searchFields.id && !searchFields.email && !searchFields.userName) return;

        const params = {};
        if(searchFields.id) params.id = searchFields.id;
        if(searchFields.email) params.email = searchFields.email;
        if(searchFields.userName) params.userName = searchFields.userName;

        dispatch(searchUsersAction(params));
    };

    return (
        <div className={styles.searchContainer}>
            <h2 className={styles.nameLbl}>{t('screens.ManageUsers.searchHeader')}</h2>
            <div>
                <TextField
                    className={styles.itemTxtField}
                    name='id'
                    label={t('screens.ManageUsers.idLbl')}
                    variant="outlined"
                    value={searchFields.id}
                    onChange={handleSearchFieldsChange}
                />
                <TextField
                    className={styles.itemTxtField}
                    name='email'
                    label={t('screens.ManageUsers.emailLbl')}
                    variant="outlined"
                    value={searchFields.email}
                    onChange={handleSearchFieldsChange}
                />
                <TextField
                    className={styles.itemTxtField}
                    name='userName'
                    label={t('screens.ManageUsers.userNameLbl')}
                    variant="outlined"
                    value={searchFields.userName}
                    onChange={handleSearchFieldsChange}
                />
            </div>
            <Button 
                onClick={onSearch} 
                variant='contained' 
                className={styles.searchBtn}
            >
                {t('screens.ManageUsers.searchBtn')}
            </Button>
        </div>
    );
};

export default Search;
