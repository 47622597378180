import { createAsyncThunk } from '@reduxjs/toolkit';
import reports from '@services/reports';

export const getReports = createAsyncThunk(
    'reports/getReportsStatus',
    async ({ onlyPending }, { rejectWithValue }) => {
        
        const response = await reports.getReports({ onlyPending });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const reviewReport = createAsyncThunk(
    'reports/reviewReportStatus',
    async ({ id, accept, onComplete, onError }, { rejectWithValue }) => {
        const response = await reports.reviewReport(id, accept);

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);
