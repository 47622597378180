import React from "react";
import { ReportType } from "@utils/constants/ReportType";
import ArticleData from './ArticleData';
import ServiceData from './ServiceData';

const Resource = ({ reportType, resourceId }) => {
    switch (reportType) {
        case ReportType.ARTICLE: {
            return <ArticleData id={resourceId} />;
        }
        case ReportType.APPRAISER: {
            return <ServiceData id={resourceId} type={reportType} />;
        }
        case ReportType.NALF: {
            return <ServiceData id={resourceId} type={reportType} />;
        }
        default: {
            return null;
        }
            
    }
};

export default Resource;
