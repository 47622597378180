import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import usePublishedArticles from '@hooks/usePublishedArticles';
import { reviewArticle } from '@redux/slices/articlesSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import ArticleData from './ArticleData';
import ConfirmationRejectDialog from './ConfirmationRejectDialog';
import SuccessRejectDialog from './SuccessRejectDialog';
import styles from './styles.module.css';
import VirtualScroll from 'react-dynamic-virtual-scroll';

const PublishedArticlesReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePublishedArticles();
    const [search, setSearch] = useState('');
    const [articleId, setArticleId] = useState('');
    const [publishedArticles, setPublishedArticles] = useState([]);
    const [confirmRejectDialogOpen, setConfirmRejectDialogOpen] = useState(false);
    const [successRejectDialogOpen, setSuccessRejectDialogOpen] = useState(false);
    const { publishedArticlesCollection } = useSelector((state) => state.articles);

    useEffect(() => {
        setPublishedArticles(publishedArticlesCollection);
    }, [publishedArticlesCollection]);

    const searchArticles = useCallback(() => {
        const filteredArticles = publishedArticlesCollection.filter((article) => {
            return article.title.toLowerCase().includes(search.toLowerCase());
        });
        setPublishedArticles(filteredArticles);
    }, [publishedArticlesCollection, search]);

    const confirmReject = () => {
        dispatch(
            reviewArticle({
                id: articleId,
                approve: false,
                onComplete: () => {
                    setSuccessRejectDialogOpen(true);
                },
                onError: () => {
                    dispatch(
                        setToastDataAction({
                            msg: t('screens.ArticleReview.rejectArticleFailedMsg'),
                            severity: 'error',
                        })
                    );
                },
            })
        );
    };

    const renderItem = useCallback(
        (rowIndex) => (
            <div key={publishedArticles[rowIndex].id} className={styles['List-item']}>
                <ArticleData article={publishedArticles[rowIndex]} />
                <div className={styles.actionsContainer}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setArticleId(publishedArticles[rowIndex].id);
                            setConfirmRejectDialogOpen(true);
                        }}
                    >
                        {t('screens.ArticleReview.rejectBtn')}
                    </Button>
                </div>
            </div>
        ),
        [publishedArticles, t]
    );

    return (
        <div className={styles.pageContainer}>
            <SuccessRejectDialog open={successRejectDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationRejectDialog
                open={confirmRejectDialogOpen}
                handleClose={() => setConfirmRejectDialogOpen(false)}
                confirmReject={confirmReject}
            />
            <Typography variant="h6" className={styles.announcementsLeft}>
                {t('screens.ArticleReview.articlesPublished')}
                {publishedArticlesCollection.length}
            </Typography>

            <div className={styles.searchContainer}>
                <TextField
                    label={t('screens.ArticleReview.search')}
                    variant="standard"
                    className={styles.searchInput}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Button variant="contained" className={styles.searchButton} onClick={searchArticles}>
                    {t('screens.ArticleReview.search')}
                </Button>
            </div>

            <VirtualScroll
                className={styles.List}
                minItemHeight={660}
                totalLength={publishedArticles.length}
                length={2}
                buffer={1}
                renderItem={renderItem}
            />
        </div>
    );
};

export default PublishedArticlesReview;
