import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPublishedArticles as getPublishedArticlesAction } from '@redux/slices/articlesSlice';

const usePublishedArticles = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);
    const { publishedArticlesCollection, requestedPublishedArticles } = useSelector((state) => state.articles);

    useEffect(() => {
        if (userData && !publishedArticlesCollection.length && !requestedPublishedArticles) {
            dispatch(getPublishedArticlesAction());
        }
    }, [userData, publishedArticlesCollection, requestedPublishedArticles, dispatch]);

    return null;
};

export default usePublishedArticles;
