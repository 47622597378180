import React from 'react';
import UserDataParams from './UserDataParams';
import styles from './styles.module.css';

const Profile = () => {
    return (
        <div className={styles.container}>
            <UserDataParams />
        </div>
    );
};

export default Profile;
