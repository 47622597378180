import { handleError } from '@/utils/handleError';
import request from './request';
import formDataRequest from './formDataRequest';

const signUp = async ({ user, password }) => {
    return await request({ url: 'add-corp-user', method: 'post', data: { user, password } });
};

const signIn = async ({ email, password, strategy, token }) => {
    let response;
    switch (strategy) {
        case 'standard':
            response = await request({
                url: 'corp-signin',
                method: 'post',
                data: { email, password }
            });
            break;
        default:
            response = await request({
                url: 'corp-signin',
                method: 'post',
                data: {
                    email,
                    password
                }
            });
    }
    if (!response.error && !response.token) return handleError({});
    if (!response.error) localStorage.setItem('AuthCorpCPToken', response.token);

    return response;
};

const signOut = async () => {
    localStorage.removeItem('AuthCorpCPToken');
    return { success: true };
};

const getUser = async () => {
    const response = await request({ url: 'get-corp-user-by-token', method: 'get' });

    if (response.error) {
        localStorage.removeItem('AuthCorpCPToken');
    }
    return response;
};

const searchUsers = async ({ id, email, userName }) => {
    if(!id && !email && !userName) return [];

    let queryParams = '';
    if(id) queryParams += `id=${id}`;
    if(queryParams !== '' && email) queryParams += '&';
    if(email) queryParams += `email=${email}`;
    if(queryParams !== '' && userName) queryParams += '&';
    if(userName) queryParams += `userName=${userName}`;
    
    return await request({ url: `search-users?${queryParams}`, method: 'get' });
};

const updateUserData = async ({ userData, files }) => {
    return await formDataRequest({
        url: 'update-corp-user',
        method: 'put',
        data: { user: userData },
        files
    });
};

const AuthService = {
    signUp,
    signIn,
    signOut,
    getUser,
    searchUsers,
    updateUserData
};

export default AuthService;
