import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Button,
    CssBaseline,
    Avatar,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import { signIn as signInAction } from '@redux/slices/accountSlice';
import { useAuthContext } from '@providers/AuthProvider';
import { ErrorReason } from '@utils/constants/ErrorReason';
import styles from './styles.module.css';

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const { login, isAuthenticated } = useAuthContext();

    useEffect(() => {
        if (isAuthenticated) navigate('/');
    }, [isAuthenticated, navigate]);

    const onComplete = () => {
        login();
        navigate('/');
    };

    const onError = (error) => {
        dispatch(
            setToastDataAction({
                msg: t(
                    error.reason === ErrorReason.REQUEST_ERROR
                        ? 'screens.SignIn.signInFailMsg'
                        : 'screens.SignIn.serverErrorMsg'
                ),
                severity: 'error'
            })
        );
    };

    const standardSignIn = async (e) => {
        e.preventDefault();
        if (loginData.email === '' || loginData.password === '') {
            return dispatch(
                setToastDataAction({
                    msg: t('screens.SignIn.incompleteFieldsMsg'),
                    severity: 'error'
                })
            );
        }

        dispatch(
            signInAction({
                email: loginData.email,
                password: loginData.password,
                strategy: 'standard',
                onComplete,
                onError
            })
        );
    };

    return (
        <Grid className={styles.container} component="main">
            <CssBaseline />
            <div className={styles.heading}>
                <Avatar className={styles.icon}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('screens.SignIn.title')}
                </Typography>
                <form className={styles.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={t('screens.SignIn.emailPH')}
                        name="email"
                        value={loginData.email}
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        value={loginData.password}
                        label={t('screens.SignIn.passwordPH')}
                        type="password"
                        autoComplete="current-password"
                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label={t('screens.SignIn.rememberMe')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={styles.signInBtn}
                        onClick={standardSignIn}
                    >
                        {t('screens.SignIn.signInBtn')}
                    </Button>
                </form>
            </div>
        </Grid>
    );
};

export default SignIn;
