import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, TextField, Button, Typography } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTranslation } from 'react-i18next';
import { updateUserData as updateUserDataAction } from '@redux/slices/accountSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import { urlToFile, getImgFromPath } from '@utils/images';
import { ImageType } from '@utils/constants/ImageType';
import styles from './styles.module.css';

const UserDataParams = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userData } = useSelector((state) => state.account);
    const [resetUserInfo, setResetUserInfo] = useState(false);
    const [selfUserInfo, setSelfUserInfo] = useState({
        id: 0,
        userName: '',
        userLastName: '',
        phone: '',
        email: '',
        userAddress: '',
        profileImgPath: '',
        articlesReviewCount: 0,
        servicesReviewCount: 0,
        img: null
    });

    useEffect(() => {
        const getUserData = async () => {
            setSelfUserInfo({
                id: userData.id,
                userName: userData.userName,
                phone: userData.phone,
                email: userData.email,
                userAddress: userData.userAddress,
                profileImgPath: userData.profileImgPath,
                img: await urlToFile(getImgFromPath(userData.profileImgPath, ImageType.PROFILE))
            });
        };
        if (userData || resetUserInfo) {
            getUserData();
            setResetUserInfo(false);
        }
    }, [userData, resetUserInfo]);

    const uploadImg = async (e) => {
        if (e.target.files?.[0] == null) return;
        setSelfUserInfo({ ...selfUserInfo, img: e.target.files[0] });
    };

    const validateUserInfo = () => {
        if (
            selfUserInfo.userName === userData.userName &&
            selfUserInfo.email === userData.email &&
            selfUserInfo.phone === userData.phone &&
            selfUserInfo.userAddress === userData.userAddress
        ) {
            return false;
        }

        if (!selfUserInfo.userName) {
            //TODO show error msg.
            return false;
        }
        return true;
    };

    const updateUserData = async () => {
        if (!validateUserInfo()) return;

        handleUpdateConfirmation();
    };

    const handleUpdateConfirmation = () => {
        dispatch(
            updateUserDataAction({
                userData: { ...userData, ...selfUserInfo },
                files: [selfUserInfo.img],
                onComplete: () => {
                    dispatch(
                        setToastDataAction({
                            msg: t('screens.Profile.UserDataParams.userUpdatedMsg'),
                            severity: 'success'
                        })
                    );
                },
                onError: () => {
                    dispatch(
                        setToastDataAction({
                            msg: t('screens.Profile.UserDataParams.updateProfileFailedMsg'),
                            severity: 'error'
                        })
                    );
                }
            })
        );
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.summaryContainer}>
                <h1 className={styles.fullName}>{selfUserInfo.userName}</h1>
                <div className={styles.userImgContainer}>
                    <Avatar
                        alt={t('screens.Profile.UserDataParams.profileImgAlt')}
                        src={
                            selfUserInfo.img
                                ? URL.createObjectURL(selfUserInfo.img)
                                : getImgFromPath(selfUserInfo.profileImgPath, 'profile')
                        }
                        className={styles.userImg}
                    />
                    <Button className={styles.userImgBtn} variant="contained" component="label">
                        {t('screens.Profile.UserDataParams.updateImgBtn')}
                        <input type="file" accept="image/*" hidden onChange={uploadImg} />
                    </Button>
                </div>
                <Typography className={styles.reviewsTitle}>
                    {t('screens.Profile.UserDataParams.reviewsTitle')}
                </Typography>
                <div className={styles.reviewsContainer}>
                    <div className={styles.reviewCount}>
                        <Typography>
                            {t('screens.Profile.UserDataParams.articlesReviewCountLbl')}
                        </Typography>
                        {userData?.articlesReviewCount}
                    </div>
                    <div className={styles.reviewCount}>
                        <Typography>
                            {t('screens.Profile.UserDataParams.servicesReviewCountLbl')}
                        </Typography>
                        {userData?.servicesReviewCount}
                    </div>
                </div>
            </div>

            <div className={styles.dataContainer}>
                <div className={styles.dataRow}>
                    <TextField
                        className={styles.txtField}
                        label={t('screens.Profile.UserDataParams.nameLbl')}
                        variant="outlined"
                        value={selfUserInfo.userName}
                        onChange={(e) =>
                            setSelfUserInfo({ ...selfUserInfo, userName: e.target.value })
                        }
                    />
                    <TextField
                        className={styles.txtField}
                        label={t('screens.Profile.UserDataParams.phoneLbl')}
                        variant="outlined"
                        value={selfUserInfo.phone}
                        onChange={(e) =>
                            setSelfUserInfo({ ...selfUserInfo, phone: e.target.value })
                        }
                    />
                </div>

                <div className={styles.dataRow}>
                    <TextField
                        className={styles.txtField}
                        disabled
                        label={t('screens.Profile.UserDataParams.emailLbl')}
                        variant="outlined"
                        value={selfUserInfo.email}
                        onChange={(e) =>
                            setSelfUserInfo({ ...selfUserInfo, email: e.target.value })
                        }
                    />
                    <TextField
                        className={styles.txtField}
                        label={t('screens.Profile.UserDataParams.addressLbl')}
                        variant="outlined"
                        value={selfUserInfo.userAddress}
                        onChange={(e) =>
                            setSelfUserInfo({ ...selfUserInfo, userAddress: e.target.value })
                        }
                    />
                </div>

                <div className={styles.btnContainer}>
                    <Button variant="contained" onClick={updateUserData}>
                        {t('screens.Profile.UserDataParams.modifyBtn')}
                    </Button>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setResetUserInfo(true)}
                    >
                        <RestartAltIcon />
                        {t('screens.Profile.UserDataParams.resetBtn')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UserDataParams;
