import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getServices as getServicesAction } from '@redux/slices/servicesSlice';

const useServices = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);

    useEffect(() => {
        if(userData) {
            dispatch(getServicesAction({ onlyPending: true }));
        }
    }, [userData, dispatch]);

    return null;
};

export default useServices;
