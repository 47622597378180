import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAnnouncements as getAnnouncementsAction } from '@redux/slices/announcementsSlice';

const useAnnouncements = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);

    useEffect(() => {
        if(userData) {
            dispatch(getAnnouncementsAction());
        }
    }, [userData, dispatch]);

    return null;
};

export default useAnnouncements;
