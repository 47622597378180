import request from './request';
import formDataRequest from './formDataRequest';

const getServices = async ({ onlyPending }) => {
    const appraisersUrl = `get-appraisers?onlyPending=${onlyPending}`;
    const notarialAndLawFirmsUrl = `get-notarial-and-law-firms?onlyPending=${onlyPending}`;
    const appraisers = await request({ url: appraisersUrl, method: 'get' });
    const notarialAndLawFirms = await request({ url: notarialAndLawFirmsUrl, method: 'get' });
    return { appraisers, notarialAndLawFirms };
};

const getPublishedServices = async () => {
    const appraisersUrl = `get-appraisers?publishedOnly=true`;
    const notarialAndLawFirmsUrl = `get-notarial-and-law-firms?publishedOnly=true`;
    const appraisers = await request({ url: appraisersUrl, method: 'get' });
    const notarialAndLawFirms = await request({ url: notarialAndLawFirmsUrl, method: 'get' });
    return { appraisers, notarialAndLawFirms };
};

const getAppraisers = async ({ latFrom, latTo, lngFrom, lngTo }) => {
    const url = `get-appraisers?latFrom=${latFrom}&latTo=${latTo}&lngFrom=${lngFrom}&lngTo=${lngTo}`;
    return await request({ url, method: 'get' });
};

const getNotarialAndLawFirms = async ({ latFrom, latTo, lngFrom, lngTo }) => {
    const url = `get-notarial-and-law-firms?latFrom=${latFrom}&latTo=${latTo}&lngFrom=${lngFrom}&lngTo=${lngTo}`;
    return await request({ url, method: 'get' });
};

const createAppraiser = async ({ appraiser, files, userId }) => {
    const url = 'add-appraiser';
    return await formDataRequest({ url, method: 'post', data: { appraiser, userId }, files });
};

const createNALF = async ({ nalf, files, userId }) => {
    const url = 'add-notarial-and-law-firm';
    return await formDataRequest({
        url,
        method: 'post',
        data: { notarialAndLawFirm: nalf, userId },
        files
    });
};

const getAppraiserById = async (id) => {
    const url = `get-appraiser-by-id/${id}`;
    return await request({ url, method: 'get' });
};

const getNALFById = async (id) => {
    const url = `get-notarial-and-law-firm-by-id/${id}`;
    return await request({ url, method: 'get' });
};

const deleteAppraiser = async ({ id }) => {
    const url = `delete-appraiser/${id}`;
    return await formDataRequest({ url, method: 'delete' });
};

const deleteNALF = async ({ id }) => {
    const url = `delete-notarial-and-law-firm/${id}`;
    return await formDataRequest({ url, method: 'delete' });
};

const reviewAppraiser = async (id, approve) => {
    const url = `approve-appraiser?id=${id}&approve=${approve}`;
    return await request({ url, method: 'put' });
};

const reviewNALF = async (id, approve) => {
    const url = `approve-notarial-and-law-firm?id=${id}&approve=${approve}`;
    return await request({ url, method: 'put' });
};

const ServicesService = {
    getServices,
    getPublishedServices,
    getAppraisers,
    getNotarialAndLawFirms,
    createAppraiser,
    createNALF,
    getAppraiserById,
    getNALFById,
    deleteAppraiser,
    deleteNALF,
    reviewAppraiser,
    reviewNALF
};

export default ServicesService;
