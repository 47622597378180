import React, { useEffect } from "react";
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getUsersByGroup as getUsersByGroupAction,
    getUserGroupById as getUserGroupByIdAction
} from '@redux/slices/usersSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import styles from './styles.module.css';

const ViewUsersByGroup = () => {
    useRequiredAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { currentUserGroup, usersByGroupCollection } = useSelector((state) => state.users);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    useEffect(() => {
        dispatch(getUsersByGroupAction({ id }));
    }, []);

    useEffect(() => {
        if (!currentUserGroup.id && id) {
            dispatch(getUserGroupByIdAction({ id }));
        }
    }, [currentUserGroup, id]);

	return (
        <div className={styles.pageContainer}>
            <h1 className={styles.pageHeader}>{t('screens.ViewUsersByGroup.pageHeader')}</h1>
            <div className={styles.groupInfo}>
                <h3>{currentUserGroup.id}</h3>
                <h2 className={styles.groupName}>{currentUserGroup.groupName}</h2>
                <h3 className={styles.groupDescription}>{currentUserGroup.description}</h3>
            </div>
            
            <section className={styles.usersContainer}>
                <h3>{t('screens.ViewUsersByGroup.usersSectionTitle')}</h3>
                {usersByGroupCollection.map(user => (
                    <div>
                        <Typography className={styles.userRow}>{user.userName} {user.email}</Typography>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default ViewUsersByGroup;
