import { ImageType } from '@utils/constants/ImageType';

export const getImgFromPath = (path, type) => {
    let imgSrc = path;
    if (!imgSrc.startsWith('http')) {
        switch (type) {
            case ImageType.PROFILE:
                // imgSrc = `${Domain.SERVER}/rest/get-user-profile-img/${imgSrc || DefaultImgPath.PROFILE}`;
                imgSrc = process.env.REACT_APP_PROFILE_IMGS_PATH + (imgSrc || 'default.jpg');
                break;
            case ImageType.ARTICLE:
                // imgSrc = `${Domain.SERVER}/rest/get-article-img/${imgSrc || DefaultImgPath.ARTICLE}`;
                imgSrc = process.env.REACT_APP_ARTICLES_IMGS_PATH + (imgSrc || 'default.jpg');
                break;
            case ImageType.APPRAISER:
                // imgSrc = `${Domain.SERVER}/rest/get-appraiser-img/${imgSrc || DefaultImgPath.APPRAISER}`;
                imgSrc = process.env.REACT_APP_APPRAISERS_IMGS_PATH + (imgSrc || 'default.jpg');
                break;
            case ImageType.NALF:
                // imgSrc = `${Domain.SERVER}/rest/get-notarial-and-law-firm-img/${imgSrc || DefaultImgPath.NALF}`;
                imgSrc = process.env.REACT_APP_NALFS_IMGS_PATH + (imgSrc || 'default.jpg');
                break;
            default:
                break;
        }
    }
    return imgSrc;
};

export const urlToFile = async (url, name = 'image') => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], `${name}.jpg`, { type: blob.type });
        return file;
    } catch (error) {
        console.log(error);
        return null;
    }
};
