import React, { useState } from 'react';
import {
    Typography,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useArticles from '@hooks/useArticles';
import { reviewArticle } from '@redux/slices/articlesSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import ArticleData from './ArticleData';
import ConfirmationApproveDialog from './ConfirmationApproveDialog';
import SuccessApproveDialog from './SuccessApproveDialog';
import ConfirmationRejectDialog from './ConfirmationRejectDialog';
import SuccessRejectDialog from './SuccessRejectDialog';
import styles from './styles.module.css';


const ArticlesReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useArticles();
    const [confirmApproveDialogOpen, setConfirmApproveDialogOpen] = useState(false);
    const [successApproveDialogOpen, setSuccessApproveDialogOpen] = useState(false);
    const [confirmRejectDialogOpen, setConfirmRejectDialogOpen] = useState(false);
    const [successRejectDialogOpen, setSuccessRejectDialogOpen] = useState(false);
    const [currentArticleIndex, setCurrentArticleIndex] = useState(0);

    const { articlesCollection } = useSelector(state => state.articles);
    const currentArticle = articlesCollection[currentArticleIndex];
    if(articlesCollection.length === 0) {
        return (
            <div className={styles.pageContainer}>

            </div>
        );
    }


    const confirmApprove = () => {
        dispatch(reviewArticle({
            id: currentArticle.id,
            approve: true,
            onComplete: () => {
                setSuccessApproveDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ArticleReview.approveArticleFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

    const confirmReject = () => {
        dispatch(reviewArticle({
            id: currentArticle.id,
            approve: false,
            onComplete: () => {
                setSuccessRejectDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ArticleReview.rejectArticleFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

    if(!currentArticle) return (
        <div className={styles.pageContainer}>
            <div className={styles.noArticlesLeftTextWrapper}>
                <Typography variant='h5' className={styles.noArticlesLeftText}>
                    {t('screens.ArticleReview.noArticlesLeft')}
                </Typography>
                <Button variant='contained' onClick={() => navigate('/')}>
                    {t('screens.ArticleReview.backBtn')}
                </Button>
            </div>
        </div>
    );

    return (
        <div className={styles.pageContainer}>
            <SuccessApproveDialog open={successApproveDialogOpen} handleClose={() => window.location.reload(false)} />
            <SuccessRejectDialog open={successRejectDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationApproveDialog
                open={confirmApproveDialogOpen}
                handleClose={() => setConfirmApproveDialogOpen(false)}
                confirmApprove={confirmApprove}
            />
            <ConfirmationRejectDialog
                open={confirmRejectDialogOpen}
                handleClose={() => setConfirmRejectDialogOpen(false)}
                confirmReject={confirmReject}
            />
            <Typography variant='h6' className={styles.announcementsLeft}>
                {t('screens.ArticleReview.announcementsLeftLbl')}
                {articlesCollection.length}
            </Typography>
            <ArticleData article={currentArticle} />
            <div className={styles.actionsContainer}>
                <Button variant='contained' onClick={() => setConfirmApproveDialogOpen(true)}>
                    {t('screens.ArticleReview.approveBtn')}
                </Button>
                <Button variant='contained' color='error' onClick={() => setConfirmRejectDialogOpen(true)}>
                    {t('screens.ArticleReview.rejectBtn')}
                </Button>
                <Button variant='contained' color='inherit' onClick={() => setCurrentArticleIndex(currentArticleIndex + 1)}>
                    {t('screens.ArticleReview.skipBtn')}
                </Button>
            </div>
        </div>
    );
};

export default ArticlesReview;
