import React, { useState } from 'react';
import {
    Typography,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useReports from '@hooks/useReports';
import { reviewReport } from '@redux/slices/reportsSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import Resource from './Resource';
import ConfirmationApproveDialog from './ConfirmationApproveDialog';
import SuccessApproveDialog from './SuccessApproveDialog';
import ConfirmationRejectDialog from './ConfirmationRejectDialog';
import SuccessRejectDialog from './SuccessRejectDialog';
import styles from './styles.module.css';

const ReportsReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useReports();
    const [confirmApproveDialogOpen, setConfirmApproveDialogOpen] = useState(false);
    const [successApproveDialogOpen, setSuccessApproveDialogOpen] = useState(false);
    const [confirmRejectDialogOpen, setConfirmRejectDialogOpen] = useState(false);
    const [successRejectDialogOpen, setSuccessRejectDialogOpen] = useState(false);
    const [currentReportIndex, setCurrentReportIndex] = useState(0);

    const { reportsCollection } = useSelector(state => state.reports);
    const currentReport = reportsCollection[currentReportIndex];
    if(reportsCollection.length === 0) {
        return (
            <div className={styles.pageContainer}>

            </div>
        );
    }


    const confirmApprove = () => {
        dispatch(reviewReport({
            id: currentReport.id,
            accept: true,
            onComplete: () => {
                setSuccessApproveDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ReportsReview.acceptReportFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

    const confirmReject = () => {
        dispatch(reviewReport({
            id: currentReport.id,
            accept: false,
            onComplete: () => {
                setSuccessRejectDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ReportsReview.rejectReportFailedMsg'),
                        severity: 'error'
                    })
                );
            }
        }));
    };

	return (
        <div className={styles.pageContainer}>
            <SuccessApproveDialog open={successApproveDialogOpen} handleClose={() => window.location.reload(false)} />
            <SuccessRejectDialog open={successRejectDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationApproveDialog
                open={confirmApproveDialogOpen}
                handleClose={() => setConfirmApproveDialogOpen(false)}
                confirmApprove={confirmApprove}
            />
            <ConfirmationRejectDialog
                open={confirmRejectDialogOpen}
                handleClose={() => setConfirmRejectDialogOpen(false)}
                confirmReject={confirmReject}
            />
            <Typography variant='h6' className={styles.announcementsLeft}>
                {t('screens.ReportsReview.reportsLeftLbl')}
                {reportsCollection.length}
            </Typography>
            <Resource resourceId={currentReport.resourceId} reportType={currentReport.type} />
            <div className={styles.reportInfoContainer}>
                <Typography className={styles.reportInfoLabel} variant='h6'>{t('screens.ReportsReview.reportTitleLbl')}</Typography>
                <Typography>{currentReport.title}</Typography>
                <Typography className={styles.reportInfoLabel} variant='h6'>{t('screens.ReportsReview.reportDescriptionLbl')}</Typography>
                <Typography>{currentReport.description}</Typography>
            </div>
            <div className={styles.actionsContainer}>
                <Button variant='contained' onClick={() => setConfirmApproveDialogOpen(true)}>
                    {t('screens.ReportsReview.acceptBtn')}
                </Button>
                <Button variant='contained' color='error' onClick={() => setConfirmRejectDialogOpen(true)}>
                    {t('screens.ReportsReview.rejectBtn')}
                </Button>
                <Button variant='contained' color='inherit' onClick={() => setCurrentReportIndex(currentReportIndex + 1)}>
                    {t('screens.ReportsReview.skipBtn')}
                </Button>
            </div>
        </div>
    );
};

export default ReportsReview;
