import React from 'react';
import styles from './styles.module.css';

const Footer = () => {
    return (
        <div className={styles.footerMainContainer}>
            <div className={styles.options + ' ' + styles.cookies}>
                <h1>Cookies</h1>
            </div>
            <div className={styles.options + ' ' + styles.privacy}>
                <h1>Políticas de privacidad</h1>
            </div>
            <div className={styles.options + ' ' + styles.legal}>
                <h1>Aviso legal</h1>
            </div>
            <div className={styles.terms}>
                <h1>Terminos y condiciones de uso</h1>
            </div>
        </div>
    );
};

export default Footer;
