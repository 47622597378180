import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const SuccessRejectDialog = ({ open, handleClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('screens.ServiceReview.successRejectDialogTitle')}</DialogTitle>
            <DialogContent>
                <div className={styles.successIconContainer}>
                    <CheckCircleOutlineIcon color="success" className={styles.successIcon} />
                </div>
                <DialogContentText>{t('screens.ServiceReview.sucessRejectDialogContent')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus variant="contained">
                    {t('screens.ServiceReview.successRejectDialogConfirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessRejectDialog;
