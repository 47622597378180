import React, { useState } from 'react';
import { Avatar, Button, Card, Typography, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getImgFromPath } from '@utils/images';
import { ImageType } from '@utils/constants/ImageType';
import styles from './styles.module.css';

const UserList = () => {
    const { t } = useTranslation();
    const { searchUsersCollection } = useSelector(state => state.account);

    const [page, setPage] = useState(1);

    const usersPerPage = 5;
    const paginationCount = Math.ceil(searchUsersCollection.length / usersPerPage);

    return (
        <div>
            {searchUsersCollection?.slice((page - 1) * 3, page * 3).map(user => (
                <Card key={user.id} className={styles.userCard}>
                    <Avatar src={getImgFromPath(user.profileImgPath, ImageType.PROFILE)} />
                    <Typography>{t('screens.ManageUsers.idLbl')}: {user.id}</Typography>
                    <Typography>{t('screens.ManageUsers.emailLbl')}: {user.email}</Typography>
                    <Typography>{t('screens.ManageUsers.userNameLbl')}: {user.userName}</Typography>
                    <Typography>{t('screens.ManageUsers.phoneLbl')}: {user.phone}</Typography>
                    <Button className={styles.editBtn} variant='contained' href={`edit-user?id=${user.id}`}>{t('screens.ManageUsers.editBtn')}</Button>
                </Card>
            ))}
            {searchUsersCollection?.length > 0 ? (
                <div className={styles.pagination}>
                    <Pagination
                        count={paginationCount}
                        variant="outlined"
                        color="primary"
                        onChange={(_, value) => setPage(value)}
                    />
                </div>
            ) : (
                <div className={styles.nothingToShow}>
                    <h1>{t('screens.ManageUsers.nothingToShow')}</h1>
                </div>
            )}
        </div>
    );
};

export default UserList;