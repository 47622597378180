import React, { useState, useEffect } from 'react';
import { 
    Card,
    Typography,
    TextField,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateAnnouncement as updateAnnouncementAction } from '@redux/slices/announcementsSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import ConfirmationDialog from './ConfirmationDialog';
import SuccessDialog from './SuccessDialog';
import styles from './styles.module.css';

const AnnouncementCard = ({ item, ...rest }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showValidation, setShowValidation] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [announcement, setAnnouncement] = useState({
        enTitle: '',
        enAdvice: '',
        enDescriptionLine1: '',
        enDescriptionLine2: '',
        esTitle: '',
        esAdvice: '',
        esDescriptionLine1: '',
        esDescriptionLine2: '',
        ptTitle: '',
        ptAdvice: '',
        ptDescriptionLine1: '',
        ptDescriptionLine2: '',
        price: 0,
        ARSPrice: 0,
        amount: 0,
        cpPoints: 0,
        rewardsInCPPoints: 0,
        displayOrder: 0,
    });
    
    useEffect(() => {
        setAnnouncement({
            ...item
        });
    }, [item]);

    const handleChange = e => {
        setAnnouncement({
            ...announcement,
            [e.target.name]: e.target.value
        });
    };

    const validateData = () => {
        if(!announcement.enTitle) return false;
        if(!announcement.esTitle) return false;
        if(!announcement.ptTitle) return false;
        if(!announcement.price) return false;
        if(!announcement.ARSPrice) return false;
        if(!announcement.amount) return false;
        if(isNaN(announcement.price)) return false;
        if(isNaN(announcement.ARSPrice)) return false;
        if(isNaN(announcement.amount)) return false;
        if(isNaN(announcement.rewardsInCPPoints)) return false;
        return true;
    };

    const confirmModification = () => {
        dispatch(updateAnnouncementAction({
            item: announcement,
            onComplete: () => {
                setSuccessDialogOpen(true);
            },
            onError: () => {
                dispatch(
                    setToastDataAction({
                        msg: t('screens.ManagePrices.moficiationFailed'),
                        severity: 'error'
                    })
                );
            }
        }));
    };


    const handleSubmit = () => {
        setShowValidation(true);

        if (!validateData()) {
            return dispatch(
                setToastDataAction({
                    msg: t('screens.ManagePrices.validationErrorMsg'),
                    severity: 'error'
                })
            );
        }

        setConfirmDialogOpen(true);
    };

    return (
        <Card className={styles.itemCard} {...rest}>
            <SuccessDialog open={successDialogOpen} handleClose={() => window.location.reload(false)} />
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                confirmUpdate={confirmModification}
            />
            <Typography className={styles.itemLbl}>{t('screens.ManagePrices.itemId')}{announcement.id}</Typography>
            <Typography className={styles.itemLbl}>{announcement.type}</Typography>
            <TextField
                error={announcement.enTitle === '' && showValidation}
                className={styles.itemTxtField}
                name="enTitle"
                label={t('screens.ManagePrices.itemEnTitle')}
                variant="outlined"
                value={announcement.enTitle}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="enAdvice"
                label={t('screens.ManagePrices.itemEnAdvice')}
                variant="outlined"
                value={announcement.enAdvice}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="enDescriptionLine1"
                label={t('screens.ManagePrices.itemEnDescriptionLine1')}
                variant="outlined"
                value={announcement.enDescriptionLine1}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="enDescriptionLine2"
                label={t('screens.ManagePrices.itemEnDescriptionLine2')}
                variant="outlined"
                value={announcement.enDescriptionLine2}
                onChange={handleChange}
            />
            <TextField
                error={announcement.esTitle === '' && showValidation}
                className={styles.itemTxtField}
                name="esTitle"
                label={t('screens.ManagePrices.itemEsTitle')}
                variant="outlined"
                value={announcement.esTitle}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="esAdvice"
                label={t('screens.ManagePrices.itemEsAdvice')}
                variant="outlined"
                value={announcement.esAdvice}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="esDescriptionLine1"
                label={t('screens.ManagePrices.itemEsDescriptionLine1')}
                variant="outlined"
                value={announcement.esDescriptionLine1}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="esDescriptionLine2"
                label={t('screens.ManagePrices.itemEsDescriptionLine2')}
                variant="outlined"
                value={announcement.esDescriptionLine2}
                onChange={handleChange}
            />
            <TextField
                error={announcement.ptTitle === '' && showValidation}
                className={styles.itemTxtField}
                name="ptTitle"
                label={t('screens.ManagePrices.itemPtTitle')}
                variant="outlined"
                value={announcement.ptTitle}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="ptAdvice"
                label={t('screens.ManagePrices.itemPtAdvice')}
                variant="outlined"
                value={announcement.ptAdvice}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="ptDescriptionLine1"
                label={t('screens.ManagePrices.itemPtDescriptionLine1')}
                variant="outlined"
                value={announcement.ptDescriptionLine1}
                onChange={handleChange}
            />
            <TextField
                className={styles.itemTxtField}
                name="ptDescriptionLine2"
                label={t('screens.ManagePrices.itemPtDescriptionLine2')}
                variant="outlined"
                value={announcement.ptDescriptionLine2}
                onChange={handleChange}
            />
            <TextField
                error={
                    (announcement.price <= 0 ||
                        announcement.price === '' ||
                        isNaN(announcement.price)) &&
                    showValidation
                }
                className={styles.itemTxtField}
                name="price"
                label={t('screens.ManagePrices.itemUSDPrice')}
                variant="outlined"
                value={announcement.price}
                type='number'
                onChange={handleChange}
            />
            <TextField
                error={
                    (announcement.ARSPrice <= 0 ||
                        announcement.ARSPrice === '' ||
                        isNaN(announcement.ARSPrice)) &&
                    showValidation
                }
                className={styles.itemTxtField}
                name="ARSPrice"
                label={t('screens.ManagePrices.itemARSPrice')}
                variant="outlined"
                value={announcement.ARSPrice}
                type='number'
                onChange={handleChange}
            />
            <TextField
                error={
                    (announcement.amount <= 0 ||
                        announcement.amount === '' ||
                        isNaN(announcement.amount)) &&
                    showValidation
                }
                className={styles.itemTxtField}
                name="amount"
                label={t('screens.ManagePrices.itemAmount')}
                variant="outlined"
                value={announcement.amount}
                type='number'
                onChange={handleChange}
                disabled={
                    announcement.id === 'singleArticle' ||
                    announcement.id === 'singleAppraiser' ||
                    announcement.id === 'singleNALF'
                }
            />
            {
                announcement.id !== 'smCPPackage' && 
                announcement.id !== 'mdCPPackage' &&
                announcement.id !== 'lgCPPackage' &&
                <TextField
                    error={
                        (announcement.cpPoints < 0 ||
                            announcement.cpPoints === '' ||
                            isNaN(announcement.cpPoints)) &&
                        showValidation
                    }
                    className={styles.itemTxtField}
                    name="cpPoints"
                    label={t('screens.ManagePrices.itemCPPoints')}
                    variant="outlined"
                    value={announcement.cpPoints}
                    type='number'
                    onChange={handleChange}
                />
            }
            <TextField
                error={
                    (announcement.rewardsInCPPoints < 0 ||
                        announcement.rewardsInCPPoints === '' ||
                        isNaN(announcement.rewardsInCPPoints)) &&
                    showValidation
                }
                className={styles.itemTxtField}
                name="rewardsInCPPoints"
                label={t('screens.ManagePrices.itemRewardsInCPPoints')}
                variant="outlined"
                value={announcement.rewardsInCPPoints}
                type='number'
                onChange={handleChange}
            />
            <TextField
                error={
                    (announcement.displayOrder <= 0 ||
                        announcement.displayOrder === '' ||
                        isNaN(announcement.displayOrder)) &&
                    showValidation
                }
                className={styles.itemTxtField}
                name="displayOrder"
                label={t('screens.ManagePrices.itemDisplayOrder')}
                variant="outlined"
                value={announcement.displayOrder}
                type='number'
                onChange={handleChange}
            />
            
            <Button variant='contained' onClick={handleSubmit}>
                {t('screens.ManagePrices.itemModifyBtn')}
            </Button>
        </Card>
    );
};

export default AnnouncementCard;