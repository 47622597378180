import React from 'react';
import Search from './Search';
import UserList from './UserList';
import styles from './styles.module.css';

const ManageUsers = () => {

    return (
        <div className={styles.container}>
            <Search />
            <UserList />
        </div>
    );
};

export default ManageUsers;
