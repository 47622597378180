import request from './request';

const getAlerts = async ({ userId }) => {
    const url = `get-alerts?userId=${userId}`;
    return await request({ url, method: 'get' });
};

const createAlert = async ({ alert }) => {
    const url = 'add-alert';
    return await request({ url, method: 'post', data: { alert } });
};

const editAlert = async ({ alert }) => {
    const url = 'update-alert';
    return await request({ url, method: 'put', data: { alert } });
};

const deleteAlert = async ({ id }) => {
    const url = `delete-alert/${id}`;
    return await request({ url, method: 'delete' });
};

const getAlertById = async (id) => {
    const url = `get-alert/${id}`;
    return await request({ url, method: 'get' });
};

const AlertsService = {
    getAlerts,
    getAlertById,
    createAlert,
    editAlert,
    deleteAlert
};

export default AlertsService;
