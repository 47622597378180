import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from '@mui/material';
import { signOut as signOutAction } from '@redux/slices/accountSlice';
import { useAuthContext } from '@providers/AuthProvider';
import MobileNavbar from './components/MobileNavbar';
import LoggedMobileNavbar from './components/LoggedMobileNavbar';
import DesktopNavbar from './components/DesktopNavbar';
import LoggedDesktopNavbar from './components/LoggedDesktopNavbar';
import WelcomeBanner from './components/WelcomeBanner';

const NavBar = () => {
    const { isAuthenticated } = useAuthContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const logOut = () => {
        dispatch(signOutAction());
        navigate('/sign-in');
        window.location.reload(false);
    };

    return isAuthenticated ? (
        <>
            <WelcomeBanner open={open} />
            {isMobile ? (
                <LoggedMobileNavbar
                    logOut={logOut}
                    anchor={anchor}
                    setAnchor={setAnchor}
                    open={open}
                    setOpen={setOpen}
                />
            ) : (
                <LoggedDesktopNavbar logOut={logOut} />
            )}
        </>
    ) : (
        <>
            {isMobile ? (
                <MobileNavbar anchor={anchor} setAnchor={setAnchor} open={open} setOpen={setOpen} />
            ) : (
                <DesktopNavbar />
            )}
        </>
    );
};

export default NavBar;
