import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from '@components/Layout';
import Home from '@screens/Home';
import SignIn from '@screens/SignIn';
import ArticlesReview from '@screens/ArticlesReview';
import ServicesReview from '@screens/ServicesReview';
import ManagePrices from '@screens/ManagePrices';
import ManageUsers from '@screens/ManageUsers';
import EditUser from '@screens/EditUser';
import Profile from '@screens/Profile';
import UserGroups from './screens/UserGroups';
import EditUserGroup from './screens/EditUserGroup';
import ViewUsersByGroup from './screens/ViewUsersByGroup';
import IncrementAnnouncementsByGroup from './screens/IncrementAnnouncementsByGroup';
import CreateUserGroup from './screens/CreateUserGroup';
import ReportsReview from './screens/ReportsReview';
import PublishedArticlesReview from './screens/PublishedArticlesReview';
import PublishedServicesReview from './screens/PublishedServicesReview';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="articles-review" element={<ArticlesReview />} />
          <Route path="published-articles-review" element={<PublishedArticlesReview />} />
          <Route path="services-review" element={<ServicesReview />} />
          <Route path="published-services-review" element={<PublishedServicesReview />} />
          <Route path="reports-review" element={<ReportsReview />} />
          <Route path="user-groups" element={<UserGroups />} />
          <Route path="manage-prices" element={<ManagePrices />} />
          <Route path="manage-users" element={<ManageUsers />} />
          <Route path="edit-user" element={<EditUser />} />
          <Route path="create-user-group" element={<CreateUserGroup />} />
          <Route path="edit-user-group" element={<EditUserGroup />} />
          <Route path="my-profile" element={<Profile />} />
          <Route path="view-users-by-group" element={<ViewUsersByGroup />} />
          <Route path="increment-announcements-by-group" element={<IncrementAnnouncementsByGroup />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
