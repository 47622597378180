import { createSlice } from '@reduxjs/toolkit';
import {
    getAlerts,
    getAlertById,
    createAlert,
    editAlert,
    deleteAlert
} from '@redux/asyncActions/alertsActions';

const initialState = {
    alertCollection: [],
    matches: [],
    loading: false,
    error: null,
    currentAlert: {
        id: '',
        title: '',
        articleType: '',
        operationType: '',
        priceFrom: '',
        priceTo: '',
        currency: '',
        financed: '',
        notMoney: '',
        bedroomsFrom: '',
        bedroomsTo: '',
        bathroomsFrom: '',
        bathroomsTo: '',
        balcony: '',
        garage: '',
        swimmingPool: '',
        appraised: '',
        hectaresFrom: '',
        hectaresTo: '',
        alertDescription: ''
    }
};

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setCurrentAlert: (state, action) => {
            state.currentAlert = action.payload;
        },
        resetCurrentAlert: (state) => {
            state.currentAlert = initialState.currentAlert;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAlerts.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAlerts.fulfilled, (state, action) => {
            state.alertCollection = action.payload.alerts;
            state.matches = action.payload.matches;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAlerts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getAlertById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAlertById.fulfilled, (state, action) => {
            state.currentAlert = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAlertById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createAlert.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createAlert.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createAlert.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(editAlert.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(editAlert.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(editAlert.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteAlert.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteAlert.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteAlert.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export { getAlerts, getAlertById, createAlert, editAlert, deleteAlert };
export const { setCurrentAlert, resetCurrentAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
