import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from './slices/accountSlice';
import alertsReducer from './slices/alertsSlice';
import articlesReducer from './slices/articlesSlice';
import servicesReducer from './slices/servicesSlice';
import toastReducer from './slices/toastSlice';
import mapReducer from './slices/mapSlice';
import announcementsReducer from './slices/announcementsSlice';
import usersReducer from './slices/usersSlice';
import reportsReducer from './slices/reportsSlice';

const persistConfig = {
    key: 'root',
    version: 1,
    whitelist: ['cart'],
    storage
};

const rootReducer = combineReducers({
    account: accountReducer,
    alerts: alertsReducer,
    articles: articlesReducer,
    services: servicesReducer,
    toast: toastReducer,
    map: mapReducer,
    announcements: announcementsReducer,
    users: usersReducer,
    reports: reportsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export let persistor = persistStore(store);
