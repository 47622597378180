import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({ open, handleClose, confirmCreation }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        confirmCreation();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('screens.CreateUserGroup.confirmationDialogTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('screens.CreateUserGroup.confirmationDialogContent')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} autoFocus variant="contained">
                    {t('screens.CreateUserGroup.confirmationDialogConfirm')}
                </Button>
                <Button onClick={handleClose} variant="contained" color="inherit">
                    {t('screens.CreateUserGroup.confirmationDialogCancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
