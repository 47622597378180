import { createAsyncThunk } from '@reduxjs/toolkit';
import announcements from '@services/announcements';

export const getAnnouncements = createAsyncThunk(
    'announcements/getAnnouncementsStatus',
    async (_, { rejectWithValue }) => {
        const response = await announcements.getAnnouncements();

        if (response.error) {
            return rejectWithValue(response.error);
        }

        response.sort((a, b) => a.displayOrder - b.displayOrder);

        return response;
    }
);

export const updateAnnouncement = createAsyncThunk(
    'announcements/updateAnnouncementStatus',
    async ({ item, onComplete, onError }, { rejectWithValue }) => {
        const response = await announcements.updateAnnouncement({ item });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);
