import request from './request';

const getReports = async ({ onlyPending }) => {
    const url = `get-reports?pendingOnly=${onlyPending}`;
    return await request({ url, method: 'get' });
};

const reviewReport = async (id, accept) => {
    const url = `review-report?id=${id}&accept=${accept}`;
    return await request({ url, method: 'put' });
};

const ReportsService = {
    getReports,
    reviewReport
};

export default ReportsService;
