import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import useRequiredAuth from '@hooks/useRequiredAuth';
import styles from './styles.module.css';
import clsx from 'clsx';

const WelcomeUser = ({ open }) => {
    useRequiredAuth();
    const { userData } = useSelector((state) => state.account);
    const navbarClasses = open ? clsx(styles.container, styles.openedNav) : clsx(styles.container);

    return (
        <Grid container className={navbarClasses}>
            <p className={styles.msg}>Bienvenido {userData?.userName}</p>
        </Grid>
    );
};

WelcomeUser.propTypes = {
    open: PropTypes.bool.isRequired
};

export default WelcomeUser;
