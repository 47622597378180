import { createAsyncThunk } from '@reduxjs/toolkit';
import services from '@services/services';

export const getServices = createAsyncThunk(
    'services/getServicesStatus',
    async ({ onlyPending }, { rejectWithValue }) => {

        const response = await services.getServices({ onlyPending });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getPublishedServices = createAsyncThunk(
    'services/getPublishedServicesStatus',
    async (_, { rejectWithValue }) => {

        const response = await services.getPublishedServices();

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getAppraisers = createAsyncThunk(
    'services/getAppraisersStatus',
    async ({ center }, { rejectWithValue, getState }) => {
        const {
            services: { filterParams }
        } = getState();
        if (!filterParams.includeApraisers) return [];

        const increment = 0.06;

        const latFrom = center.lat - increment;
        const latTo = center.lat + increment;
        const lngFrom = center.lng - increment;
        const lngTo = center.lng + increment;

        const response = await services.getAppraisers({ latFrom, latTo, lngFrom, lngTo });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getNotarialAndLawFirms = createAsyncThunk(
    'services/getNotarialAndLawFirmsStatus',
    async ({ center }, { rejectWithValue, getState }) => {
        const {
            services: { filterParams }
        } = getState();
        if (!filterParams.includeLawFirms) return [];

        const increment = 0.06;

        const latFrom = center.lat - increment;
        const latTo = center.lat + increment;
        const lngFrom = center.lng - increment;
        const lngTo = center.lng + increment;

        const response = await services.getNotarialAndLawFirms({ latFrom, latTo, lngFrom, lngTo });

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const createAppraiser = createAsyncThunk(
    'services/createAppraiserStatus',
    async ({ appraiser, userId, files, onComplete, onError }, { rejectWithValue }) => {
        const response = await services.createAppraiser({ appraiser, userId, files });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const createNALF = createAsyncThunk(
    'services/createNALFStatus',
    async ({ nalf, userId, files, onComplete, onError }, { rejectWithValue }) => {
        const response = await services.createNALF({ nalf, userId, files });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const getAppraiserById = createAsyncThunk(
    'services/getAppraiserByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await services.getAppraiserById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const getNALFById = createAsyncThunk(
    'services/getNALFByIdStatus',
    async ({ id }, { rejectWithValue }) => {
        const response = await services.getNALFById(id);

        if (response.error) {
            return rejectWithValue(response);
        }

        return response;
    }
);

export const deleteAppraiser = createAsyncThunk(
    'services/deleteAppraiserStatus',
    async ({ id, onComplete, onError }, { rejectWithValue }) => {
        const response = await services.deleteAppraiser({ id });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const deleteNALF = createAsyncThunk(
    'services/deleteNALFStatus',
    async ({ id, onComplete, onError }, { rejectWithValue }) => {
        const response = await services.deleteNALF({ id });

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);

export const reviewService = createAsyncThunk(
    'services/reviewServiceStatus',
    async ({ id, approve, type, onComplete, onError }, { rejectWithValue }) => {
        let response;
        if(type === 'appraiser') {
            response = await services.reviewAppraiser(id, approve);
        } else {
            response = await services.reviewNALF(id, approve);
        }

        if (response.error) {
            onError(response);
            return rejectWithValue(response);
        }

        onComplete(response);
        return response;
    }
);
