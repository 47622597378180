import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    addUserGroup as addUserGroupAction
} from '@redux/slices/usersSlice';
import { setToastData as setToastDataAction } from '@redux/slices/toastSlice';
import useRequiredAuth from '@hooks/useRequiredAuth';
import ConfirmationDialog from "./ConfirmationDialog";
import styles from './styles.module.css';

const CreateUserGroup = () => {
    useRequiredAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [ userGroupFields, setUserGroupFields ] = useState({
        groupName: '',
        description: ''
    });

    const handleUserGroupFieldsChange = e => {
        setUserGroupFields({
            ...userGroupFields,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        if (!userGroupFields.groupName) return;
        setConfirmDialogOpen(true);
    };

    const confirmCreation = () => {
        dispatch(addUserGroupAction({
            userGroupData: userGroupFields,
            onComplete: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.CreateUserGroup.createUserGroupSuccessMsg'),
                    severity: 'success'
                }));
                navigate('/');
            },
            onError: () => {
                dispatch(setToastDataAction({
                    msg: t('screens.CreateUserGroup.createUserGroupFailedMsg'),
                    severity: 'error'
                }));
            }
        }));
    };

	return (
        <div className={styles.pageContainer}>
            <ConfirmationDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                confirmCreation={confirmCreation}
            />
            <h1 className={styles.pageHeader}>{t('screens.CreateUserGroup.pageHeader')}</h1>
            <TextField
                className={styles.itemTxtField}
                name='groupName'
                label={t('screens.CreateUserGroup.groupNameLbl')}
                variant="outlined"
                value={userGroupFields.groupName}
                onChange={handleUserGroupFieldsChange}
                required
            />
            <TextField
                className={styles.itemTxtField}
                name='description'
                label={t('screens.CreateUserGroup.descriptionLbl')}
                variant="outlined"
                value={userGroupFields.description}
                onChange={handleUserGroupFieldsChange}
            />
            <Button 
                variant='contained'
                onClick={handleSubmit}
            >
                {t('screens.CreateUserGroup.createBtn')}
            </Button>
        </div>
    );
};

export default CreateUserGroup;
