import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPublishedServices as getPublishedServicesAction } from '@redux/slices/servicesSlice';

const usePublishedServices = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.account);
    const { publishedAppraisersCollection, publishedNotarialAndLawFirmsCollection, requestedPublishedServices } = useSelector(
        (state) => state.services
    );

    useEffect(() => {
        if (
            userData &&
            !publishedAppraisersCollection.length &&
            !publishedNotarialAndLawFirmsCollection.length &&
            !requestedPublishedServices
        ) {
            dispatch(getPublishedServicesAction({ onlyPending: true }));
        }
    }, [userData, publishedAppraisersCollection, publishedNotarialAndLawFirmsCollection, requestedPublishedServices, dispatch]);

    return null;
};

export default usePublishedServices;
