import axios from 'axios';
import { URL } from '@utils/constants/URL';
import { handleError } from '@/utils/handleError';

const API_URL = URL.SERVER + '/rest/';

const client = axios.create({
    baseURL: API_URL
});

export const request = async ({ url, method, data = {}, files = [] }) => {
    const authToken = localStorage.getItem('AuthCorpCPToken');
    client.defaults.headers['Authorization'] = authToken;

    const formData = new FormData();

    files.forEach((file, idx) => {
        formData.append(`${file.name}-${idx}`, file);
    });

    Object.keys(data).forEach((key) => {
        formData.append(key, JSON.stringify(data[key]));
    });

    try {
        const response = await client[method](url, formData);

        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

export default request;
