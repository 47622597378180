import React, { useState, useEffect } from 'react';
import { 
    Card,
    Typography,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAppraiserById as getAppraiserByIdAction,
    getNALFById as getNALFByIdAction
} from '@redux/slices/servicesSlice';
import { ReportType } from "@utils/constants/ReportType";
import { urlToFile, getImgFromPath } from '@utils/images';
import { ImageType } from '@utils/constants/ImageType';
import { DefaultImgPath } from '@utils/constants/DefaultImgPath';
import styles from './styles.module.css';

const ServiceData = ({ id, type }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [imgFile, setImgFile] = useState(null);

    const { currentService: service} = useSelector(state => state.services);

    useEffect(() => {
        if (id && !service.id && type === ReportType.APPRAISER) {
            dispatch(getAppraiserByIdAction({ id }));
        } else if (id && !service.id && type === ReportType.NALF) {
            dispatch(getNALFByIdAction({ id }));
        }
    }, [id, service, dispatch, type]);

    useEffect(() => {
        const loadImgs = async () => {
            let img = null;
            if (service.imgPath !== DefaultImgPath.APPRAISER && type === ReportType.APPRAISER) {
                img = await urlToFile(getImgFromPath(service.imgPath, ImageType.APPRAISER));
            }
            if (service.imgPath !== DefaultImgPath.NALF && type === ReportType.NALF) {
                img = await urlToFile(getImgFromPath(service.imgPath, ImageType.NALF));
            }
            setImgFile(img);
        }
        loadImgs();
    }, [service.imgs, service.imgPath, type]);

    return (
        <Card className={styles.serviceContainer}>
            <div className={styles.serviceImgContainer}>
                <ImageList className={styles.imgList}>
                        {imgFile ? (
                        <ImageListItem>
                            <img alt={t('screens.ReportsReview.altImgText')} src={URL.createObjectURL(imgFile)} loading="lazy" />
                            <ImageListItemBar
                                title={imgFile.name}
                            />
                        </ImageListItem>
                    ) : (
                        []
                    )}
                </ImageList>
            </div>
            <div className={styles.infoContainer}>
                <Typography variant='h5'>
                    <span className={styles.serviceLbl}>{t(`screens.ReportsReview.serviceType.${type}`)}</span> 
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.serviceNameLbl')}</span> 
                    {service.serviceName}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.serviceLastNameLbl')}</span> 
                    {service.serviceLastName}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.phoneLbl')}</span> 
                    {service.phone}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.addressLbl')}</span> 
                    {service.serviceAddress}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.collegeLbl')}</span> 
                    {service.college}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.enrollmentLbl')}</span> 
                    {service.enrollment}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.institutionEmailLbl')}</span> 
                    {service.institutionEmail}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.institutionPhoneLbl')}</span> 
                    {service.institutionPhone}
                </Typography>
                <Typography variant='h6'>
                    <span className={styles.serviceLbl}>{t('screens.ReportsReview.institutionAddressLbl')}</span> 
                    {service.institutionAddress}
                </Typography>
            </div>
        </Card>
    );
};

export default ServiceData;
